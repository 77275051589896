.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: var(--spacing-0);
    left: var(--spacing-0);
    background: white;
}

.icon svg {
    width: var(--spacing-12);
    height: var(--spacing-12);
}
