var Qa = Object.defineProperty, Za = Object.defineProperties;
var Xa = Object.getOwnPropertyDescriptors;
var vt = Object.getOwnPropertySymbols;
var _a = Object.prototype.hasOwnProperty, oa = Object.prototype.propertyIsEnumerable;
var Lt = (t, a, r) => a in t ? Qa(t, a, { enumerable: !0, configurable: !0, writable: !0, value: r }) : t[a] = r, w = (t, a) => {
  for (var r in a || (a = {}))
    _a.call(a, r) && Lt(t, r, a[r]);
  if (vt)
    for (var r of vt(a))
      oa.call(a, r) && Lt(t, r, a[r]);
  return t;
}, A = (t, a) => Za(t, Xa(a));
var B = (t, a) => {
  var r = {};
  for (var _ in t)
    _a.call(t, _) && a.indexOf(_) < 0 && (r[_] = t[_]);
  if (t != null && vt)
    for (var _ of vt(t))
      a.indexOf(_) < 0 && oa.call(t, _) && (r[_] = t[_]);
  return r;
};
var Ot = (t, a, r) => (Lt(t, typeof a != "symbol" ? a + "" : a, r), r);
import * as e from "react";
import { useReducer as er, useRef as tr, useEffect as tt, useCallback as ht, useMemo as kt } from "react";
import { ChevronDown as bt, Close as Te, Info as Zt, Warning as Ta, CheckCircle as Da, Block as Ia, Person as ar, ChevronUp as Ft, VisibilityOn as rr, VisibilityOff as _r, Check as Xt, Search as or, LockBlackFilled as lr, Refresh as cr, Error as nr } from "@livechat/design-system-icons/react/tabler";
import g, { clsx as Le } from "clsx";
import { useFloating as Aa, offset as Ba, flip as Pa, autoUpdate as Fa, arrow as sr } from "@floating-ui/react-dom";
import { css as at, cx as ir } from "@emotion/css";
import { CSSTransition as ea, TransitionGroup as Ra } from "react-transition-group";
import { Close as Ma, DoubleArrowLeft as dr, ChevronLeft as ur, ChevronRight as mr, DoubleArrowRight as pr } from "@livechat/design-system-icons/react/material";
import La from "lodash.debounce";
import { getContrast as ta } from "polished";
import gr from "react-day-picker";
import { subMonths as Xe, differenceInCalendarMonths as la, addMonths as ca, isSameMonth as qt, isSameDay as Jt, isAfter as Kt, differenceInCalendarDays as Oa } from "date-fns";
import * as fr from "react-dom";
import na from "lodash.escape";
const il = {
  Background: "--background",
  // deprecated
  Background01: "--background-01",
  Background02: "--background-02",
  Background03: "--background-03",
  SurfaceBasicDefault: "--surface-basic-default",
  // deprecated
  SurfacePrimaryDefault: "--surface-primary-default",
  SurfaceBasicSubtle: "--surface-basic-subtle",
  // deprecated
  SurfaceSecondaryDefault: "--surface-secondary-default",
  SurfaceSecondaryHover: "--surface-secondary-hover",
  SurfaceSecondaryActive: "--surface-secondary-active",
  SurfaceSecondaryDisabled: "--surface-secondary-disabled",
  SurfaceBasicHover: "--surface-basic-hover",
  // deprecated
  SurfacePrimaryHover: "--surface-primary-hover",
  SurfaceBasicActive: "--surface-basic-active",
  // deprecated
  SurfacePrimaryActive: "--surface-primary-active",
  SurfacePrimaryActiveColored: "--surface-primary-active-colored",
  SurfaceBasicDisabled: "--surface-basic-disabled",
  // deprecated
  SurfacePrimaryDisabled: "--surface-primary-disabled",
  SurfaceTertiaryDefault: "--surface-tertiary-default",
  SurfaceSecondarySubtle: "--surface-secondary-subtle",
  // deprecated
  SurfaceModerateDefault: "--surface-moderate-default",
  SurfaceModerateHover: "--surface-moderate-hover",
  SurfaceModerateActive: "--surface-moderate-active",
  SurfaceTertiaryHover: "--surface-tertiary-hover",
  SurfaceTertiaryActive: "--surface-tertiary-active",
  SurfaceTertiaryDisabled: "--surface-tertiary-disabled",
  SurfaceFeedbackInfo: "--surface-feedback-info",
  // deprecated
  SurfaceAccentEmphasisLowInfo: "--surface-accent-emphasis-low-info",
  SurfaceFeedbackNegative: "--surface-feedback-negative",
  // deprecated
  SurfaceAccentEmphasisLowNegative: "--surface-accent-emphasis-low-negative",
  SurfaceFeedbackWarning: "--surface-feedback-warning",
  // deprecated
  SurfaceAccentEmphasisLowWarning: "--surface-accent-emphasis-low-warning",
  SurfaceFeedbackPositive: "--surface-feedback-positive",
  // deprecated
  SurfaceAccentEmphasisLowPositive: "--surface-accent-emphasis-low-positive",
  SurfaceAccentEmphasisLowPurple: "--surface-accent-emphasis-low-purple",
  SurfaceAccentEmphasisMinInfo: "--surface-accent-emphasis-min-info",
  SurfaceAccentEmphasisMinNegative: "--surface-accent-emphasis-min-negative",
  SurfaceAccentEmphasisMinWarning: "--surface-accent-emphasis-min-warning",
  SurfaceAccentEmphasisMinPositive: "--surface-accent-emphasis-min-positive",
  SurfaceAccentEmphasisMinPurple: "--surface-accent-emphasis-min-purple",
  SurfaceInvertDefault: "--surface-invert-default",
  // deprecated
  SurfaceInvertPrimary: "--surface-invert-primary",
  SurfaceInvertSubtle: "--surface-invert-subtle",
  // deprecated
  SurfaceInvertSecondary: "--surface-invert-secondary",
  SurfaceInvertDisabled: "--surface-invert-disabled",
  SurfaceLockedDefault: "--surface-locked-default",
  SurfaceLockedHover: "--surface-locked-hover",
  SurfaceLockedActive: "--surface-locked-active",
  SurfaceLockedDisabled: "--surface-locked-disabled",
  SurfaceLockedWhite: "--surface-locked-white",
  SurfaceLockedBlack: "--surface-locked-black",
  SurfaceAccentEmphasisHighInfo: "--surface-accent-emphasis-high-info",
  SurfaceAccentEmphasisHighNegative: "--surface-accent-emphasis-high-negative",
  SurfaceAccentEmphasisHighWarning: "--surface-accent-emphasis-high-warning",
  SurfaceAccentEmphasisHighPositive: "--surface-accent-emphasis-high-positive",
  SurfaceAccentEmphasisHighPurple: "--surface-accent-emphasis-high-purple",
  SurfaceOverlay: "--surface-overlay",
  // deprecated
  SurfaceOtherOverlay: "--surface-other-overlay",
  SurfaceOtherAgent: "--surface-other-agent",
  SurfaceOtherSkeleton: "--surface-other-skeleton",
  SurfaceGradient01: "--surface-gradient-01",
  SurfaceGradient02: "--surface-gradient-02",
  ContentDefault: "--content-default",
  // deprecated
  ContentBasicPrimary: "--content-basic-primary",
  ContentSubtle: "--content-subtle",
  // deprecated
  ContentBasicSecondary: "--content-basic-secondary",
  ContentDisabled: "--content-disabled",
  // deprecated
  ContentBasicDisabled: "--content-basic-disabled",
  ContentBasicNegative: "--content-basic-negative",
  ContentBasicWarning: "--content-basic-warning",
  ContentBasicPositive: "--content-basic-positive",
  ContentBasicInfo: "--content-basic-info",
  ContentBasicPurple: "--content-basic-purple",
  ContentBasicGradient01: "--content-basic-gradient-01",
  ContentWhiteLocked: "--content-white-locked",
  // deprecated
  ContentLockedWhite: "--content-locked-white",
  ContentBlackLocked: "--content-black-locked",
  // deprecated
  ContentLockedBlack: "--content-locked-black",
  ContentLockedDefault: "--content-locked-default",
  ContentLockedHover: "--content-locked-hover",
  ContentLockedActive: "--content-locked-active",
  ContentLockedDisabled: "--content-locked-disabled",
  ContentInvertDefault: "--content-invert-default",
  // deprecated
  ContentInvertPrimary: "--content-invert-primary",
  ContentInvertSubtle: "--content-invert-subtle",
  // deprecated
  ContentInvertSecondary: "--content-invert-secondary",
  ContentInvertDisabled: "--content-invert-disabled",
  ContentInvertGradient01: "--content-invert-gradient-01",
  BorderDefault: "--border-default",
  // deprecated
  BorderBasicPrimary: "--border-basic-primary",
  BorderBasicSecondary: "--border-basic-secondary",
  BorderSubtle: "--border-subtle",
  // deprecated
  BorderBasicTertiary: "--border-basic-tertiary",
  BorderHover: "--border-hover",
  // deprecated
  BorderBasicHover: "--border-basic-hover",
  BorderDisabled: "--border-disabled",
  // deprecated
  BorderBasicDisabled: "--border-basic-disabled",
  BorderBasicNegative: "--border-basic-negative",
  BorderBasicWarning: "--border-basic-warning",
  BorderBasicPositive: "--border-basic-positive",
  BorderBasicInfo: "--border-basic-info",
  BorderBasicPurple: "--border-basic-purple",
  BorderInvertDefault: "--border-invert-default",
  // deprecated
  BorderInvertPrimary: "--border-invert-primary",
  BorderInvertSubtle: "--border-invert-subtle",
  // deprecated
  BorderInvertSecondary: "--border-invert-secondary",
  BorderInvertHover: "--border-invert-hover",
  BorderInvertDisabled: "--border-invert-disabled",
  ColorActionActive: "--color-action-active",
  // deprecated
  ActionPrimaryActive: "--action-primary-active",
  ColorActionHover: "--color-action-hover",
  // deprecated
  ActionPrimaryHover: "--action-primary-hover",
  ColorActionDefault: "--color-action-default",
  // deprecated
  ActionPrimaryDefault: "--action-primary-default",
  ColorActionDefaultRgb: "--color-action-default-rgb",
  ColorActionDisabled: "--color-action-disabled",
  // deprecated
  ActionPrimaryDisabled: "--action-primary-disabled",
  ColorNegativeActive: "--color-negative-active",
  // deprecated
  ActionNegativeActive: "--action-negative-active",
  ColorNegativeHover: "--color-negative-hover",
  // deprecated
  ActionNegativeHover: "--action-negative-hover",
  ColorNegativeDefault: "--color-negative-default",
  // deprecated
  ActionNegativeDefault: "--action-negative-default",
  ColorNegativeDisabled: "--color-negative-disabled",
  // deprecated
  ActionNegativeDisabled: "--action-negative-disabled",
  ColorWarningDefault: "--color-warning-default",
  // deprecated
  ActionWarningDefault: "--action-warning-default",
  ColorWarningHover: "--color-warning-hover",
  // deprecated
  ActionWarningHover: "--action-warning-hover",
  ColorPositiveDefault: "--color-positive-default",
  // deprecated
  ActionPositiveDefault: "--action-positive-default",
  ColorPositiveHover: "--color-positive-hover",
  // deprecated
  ActionPositiveHover: "--action-positive-hover",
  ColorPositiveDisabled: "--color-positive-disabled",
  // deprecated
  ActionPositiveDisabled: "--action-positive-disabled",
  ActionNeutralDefault: "--action-neutral-default",
  ActionNeutralHover: "--action-neutral-hover",
  ActionNeutralDisabled: "--action-neutral-disabled",
  ColorBot: "--color-bot",
  // deprecated
  SurfaceOtherBot: "--surface-other-bot",
  ColorBlack: "--color-black",
  ColorWhite: "--color-white",
  DecorBlue900: "--decor-blue900",
  DecorBlue800: "--decor-blue800",
  DecorBlue700: "--decor-blue700",
  DecorBlue600: "--decor-blue600",
  DecorBlue500: "--decor-blue500",
  DecorBlue400: "--decor-blue400",
  DecorBlue300: "--decor-blue300",
  DecorBlue200: "--decor-blue200",
  DecorBlue100: "--decor-blue100",
  DecorBlue50: "--decor-blue50",
  DecorOrange900: "--decor-orange900",
  DecorOrange800: "--decor-orange800",
  DecorOrange700: "--decor-orange700",
  DecorOrange600: "--decor-orange600",
  DecorOrange500: "--decor-orange500",
  DecorOrange400: "--decor-orange400",
  DecorOrange300: "--decor-orange300",
  DecorOrange200: "--decor-orange200",
  DecorOrange100: "--decor-orange100",
  DecorOrange50: "--decor-orange50",
  DecorYellow900: "--decor-yellow900",
  DecorYellow800: "--decor-yellow800",
  DecorYellow700: "--decor-yellow700",
  DecorYellow600: "--decor-yellow600",
  DecorYellow500: "--decor-yellow500",
  DecorYellow400: "--decor-yellow400",
  DecorYellow300: "--decor-yellow300",
  DecorYellow200: "--decor-yellow200",
  DecorYellow100: "--decor-yellow100",
  DecorYellow50: "--decor-yellow50",
  DecorGray950: "--decor-gray950",
  DecorGray900: "--decor-gray900",
  DecorGray800: "--decor-gray800",
  DecorGray700: "--decor-gray700",
  DecorGray600: "--decor-gray600",
  DecorGray500: "--decor-gray500",
  DecorGray400: "--decor-gray400",
  DecorGray300: "--decor-gray300",
  DecorGray200: "--decor-gray200",
  DecorGray150: "--decor-gray150",
  DecorGray100: "--decor-gray100",
  DecorGray75: "--decor-gray75",
  DecorGray50: "--decor-gray50",
  DecorGray40: "--decor-gray40",
  DecorGray20: "--decor-gray20",
  DecorGreen900: "--decor-green900",
  DecorGreen800: "--decor-green800",
  DecorGreen700: "--decor-green700",
  DecorGreen600: "--decor-green600",
  DecorGreen500: "--decor-green500",
  DecorGreen400: "--decor-green400",
  DecorGreen300: "--decor-green300",
  DecorGreen200: "--decor-green200",
  DecorGreen100: "--decor-green100",
  DecorGreen50: "--decor-green50",
  DecorRed900: "--decor-red900",
  DecorRed800: "--decor-red800",
  DecorRed700: "--decor-red700",
  DecorRed600: "--decor-red600",
  DecorRed500: "--decor-red500",
  DecorRed400: "--decor-red400",
  DecorRed300: "--decor-red300",
  DecorRed200: "--decor-red200",
  DecorRed100: "--decor-red100",
  DecorRed50: "--decor-red50",
  DecorPurple900: "--decor-purple900",
  DecorPurple800: "--decor-purple800",
  DecorPurple700: "--decor-purple700",
  DecorPurple600: "--decor-purple600",
  DecorPurple500: "--decor-purple500",
  DecorPurple400: "--decor-purple400",
  DecorPurple300: "--decor-purple300",
  DecorPurple200: "--decor-purple200",
  DecorPurple100: "--decor-purple100",
  DecorPurple50: "--decor-purple50",
  ProductsLivechat: "--products-livechat",
  ProductsHelpdesk: "--products-helpdesk",
  ProductsChatbot: "--products-chatbot",
  ProductsKnowledgebase: "--products-knowledgebase",
  IllustrationsPrimary: "--illustrations-primary",
  IllustrationsSecondary: "--illustrations-secondary",
  IllustrationsStroke: "--illustrations-stroke",
  TagSurface01: "--tag-surface-01",
  TagContent01: "--tag-content-01"
}, dl = {
  Spacing0: "--spacing-0",
  Spacing05: "--spacing-05",
  Spacing1: "--spacing-1",
  Spacing2: "--spacing-2",
  Spacing3: "--spacing-3",
  Spacing4: "--spacing-4",
  Spacing5: "--spacing-5",
  Spacing6: "--spacing-6",
  Spacing8: "--spacing-8",
  Spacing12: "--spacing-12",
  Spacing14: "--spacing-14",
  Spacing16: "--spacing-16",
  Spacing18: "--spacing-18",
  Spacing20: "--spacing-20",
  Spacing24: "--spacing-24"
}, ul = {
  Float: "--shadow-float",
  PopOver: "--shadow-pop-over",
  Modal: "--shadow-modal",
  Focus: "--shadow-focus"
}, ml = {
  0: "--radius-0",
  1: "--radius-1",
  2: "--radius-2",
  3: "--radius-3"
}, L = {
  esc: "Escape",
  enter: "Enter",
  backspace: "Backspace",
  delete: "Delete",
  spacebar: " ",
  tab: "Tab",
  semicolon: ";",
  comma: ",",
  arrowUp: "ArrowUp",
  arrowDown: "ArrowDown"
}, br = "lc-Typography-module__caps___c3eNJ", Ze = {
  "heading-xl": "lc-Typography-module__heading-xl___nhr-6",
  "heading-lg": "lc-Typography-module__heading-lg___XYF7l",
  "heading-md": "lc-Typography-module__heading-md___wHOIs",
  "heading-sm": "lc-Typography-module__heading-sm___nBmTV",
  "heading-xs": "lc-Typography-module__heading-xs___VcZBs",
  caps: br,
  "caps--bold": "lc-Typography-module__caps--bold___OZovw",
  "paragraph-md": "lc-Typography-module__paragraph-md___UGoqq",
  "paragraph-md--bold": "lc-Typography-module__paragraph-md--bold___mWcMG",
  "paragraph-md--underline": "lc-Typography-module__paragraph-md--underline___Fv6zM",
  "paragraph-md--strike": "lc-Typography-module__paragraph-md--strike___yLGHh",
  "paragraph-sm": "lc-Typography-module__paragraph-sm___QIEFL",
  "paragraph-sm--bold": "lc-Typography-module__paragraph-sm--bold___fAjI5",
  "paragraph-xs": "lc-Typography-module__paragraph-xs___OUooD",
  "paragraph-xs--bold": "lc-Typography-module__paragraph-xs--bold___z7om7",
  "display-md": "lc-Typography-module__display-md___7eFCF",
  "display-sm": "lc-Typography-module__display-sm___kfNES"
}, vr = {
  xl: "h1",
  lg: "h2",
  md: "h3",
  sm: "h4",
  xs: "h5"
}, et = (l) => {
  var c = l, {
    as: t,
    size: a = "md",
    children: r,
    className: _
  } = c, o = B(c, [
    "as",
    "size",
    "children",
    "className"
  ]);
  return e.createElement(
    t || vr[a],
    w({ className: g(Ze[`heading-${a}`], _) }, o),
    r
  );
}, P = (i) => {
  var u = i, {
    as: t = "p",
    size: a = "md",
    caps: r = !1,
    bold: _ = !1,
    underline: o = !1,
    strike: l = !1,
    children: c,
    className: n
  } = u, s = B(u, [
    "as",
    "size",
    "caps",
    "bold",
    "underline",
    "strike",
    "children",
    "className"
  ]);
  const m = r ? "caps" : `paragraph-${a}`;
  return e.createElement(
    t,
    w({
      className: g(
        {
          [Ze[`${m}`]]: !0,
          [Ze[`${m}--bold`]]: _,
          [Ze[`${m}--strike`]]: l,
          [Ze[`${m}--underline`]]: o
        },
        n
      )
    }, s),
    c
  );
}, pl = (l) => {
  var c = l, {
    as: t = "div",
    size: a = "md",
    children: r,
    className: _
  } = c, o = B(c, [
    "as",
    "size",
    "children",
    "className"
  ]);
  return e.createElement(
    t,
    w({ className: g(Ze[`display-${a}`], _) }, o),
    r
  );
}, hr = "lc-Popover-module__popover___8X1b2", Ht = {
  "popover-trigger": "lc-Popover-module__popover-trigger___SwsY-",
  popover: hr,
  "popover--visible": "lc-Popover-module__popover--visible___u5NXB"
}, kr = ({
  triggerRenderer: t,
  onClose: a,
  children: r,
  className: _,
  triggerClassName: o,
  placement: l,
  flipOptions: c,
  isVisible: n = !1,
  closeOnEsc: s = !0
}) => {
  const [i, u] = e.useState(!1), m = e.useRef(!1), d = typeof r == "string", {
    x: v,
    y: f,
    reference: p,
    floating: b,
    strategy: E,
    refs: h,
    update: N,
    placement: $
  } = Aa({
    middleware: [Ba(4), Pa(c)],
    placement: l
  });
  e.useEffect(() => {
    u(n);
  }, [n]), e.useEffect(() => {
    a && m.current !== i && !i && a(), m.current = i;
  }, [i]), e.useEffect(() => {
    const x = (H) => {
      s && H.key === "Escape" && u(!1);
    };
    return document.addEventListener("keydown", x), () => {
      document.removeEventListener("keydown", x);
    };
  }, [s]), e.useEffect(() => {
    if (!(!h.reference.current || !h.floating.current))
      return Fa(h.reference.current, h.floating.current, N);
  }, [h.reference, h.floating, N, $, i]);
  function y(x) {
    h.floating.current && h.floating.current.contains(x.target) || (h.reference.current && h.reference.current.contains(x.target) ? u((H) => !H) : u(!1));
  }
  e.useEffect(() => (document.addEventListener("mousedown", y), () => {
    document.removeEventListener("mousedown", y);
  }), []);
  const k = g(Ht.popover, _, {
    [Ht["popover--visible"]]: i
  }), C = g(
    Ht["popover-trigger"],
    o
  );
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("div", { className: C, ref: p }, t()), /* @__PURE__ */ e.createElement(
    "div",
    {
      ref: b,
      className: k,
      style: {
        position: E,
        top: f != null ? f : "",
        left: v != null ? v : ""
      }
    },
    d ? /* @__PURE__ */ e.createElement(P, { as: "div" }, r) : r
  ));
}, Ue = {
  "action-menu__trigger-button": "lc-ActionMenu-module__action-menu__trigger-button___tBrz5",
  "action-menu__list": "lc-ActionMenu-module__action-menu__list___9pNUX",
  "action-menu__list__group-header": "lc-ActionMenu-module__action-menu__list__group-header___n-DTq",
  "action-menu__list__item": "lc-ActionMenu-module__action-menu__list__item___n-OgH",
  "action-menu__list__item--with-divider": "lc-ActionMenu-module__action-menu__list__item--with-divider___2H3Vm",
  "action-menu__list__item--disabled": "lc-ActionMenu-module__action-menu__list__item--disabled___MgaX8"
}, We = "action-menu", yr = (s) => {
  var i = s, {
    className: t,
    triggerClassName: a,
    options: r,
    triggerRenderer: _,
    placement: o = "bottom-end",
    openedOnInit: l = !1,
    keepOpenOnClick: c
  } = i, n = B(i, [
    "className",
    "triggerClassName",
    "options",
    "triggerRenderer",
    "placement",
    "openedOnInit",
    "keepOpenOnClick"
  ]);
  const [u, m] = e.useState(l), d = e.useRef(-1), v = e.useRef(null), f = ($) => {
    var C, x;
    const y = d.current;
    let k = y + $;
    for (; (C = r[k]) != null && C.disabled || (x = r[k]) != null && x.groupHeader; )
      if (k += $, k === -1) {
        k = y;
        break;
      }
    return k;
  }, p = ($) => {
    var C, x;
    d.current = f($);
    const y = (C = v.current) == null ? void 0 : C.children, k = y && ((x = y[d.current]) == null ? void 0 : x.children[0]);
    return k == null ? void 0 : k.focus();
  }, b = ($) => {
    $.key === L.arrowUp && d.current > 0 && ($.preventDefault(), p(-1)), $.key === L.arrowDown && d.current + 1 < r.length && ($.preventDefault(), p(1));
  };
  e.useEffect(() => {
    if (u)
      return document.addEventListener("keydown", b), () => document.removeEventListener("keydown", b);
    d.current = -1;
  }, [u, b]);
  const E = () => {
    m(!0);
  }, h = ($, y) => {
    d.current = $, y == null || y(), c || m(!1);
  }, N = ($, y) => $.groupHeader ? /* @__PURE__ */ e.createElement(
    "li",
    {
      key: $.key,
      role: "none",
      className: Ue[`${We}__list__group-header`]
    },
    $.element
  ) : /* @__PURE__ */ e.createElement("li", { key: $.key, role: "none" }, /* @__PURE__ */ e.createElement(
    "button",
    {
      "data-testid": $.key,
      tabIndex: -1,
      key: $.key,
      disabled: $.disabled,
      onClick: () => h(y, $.onClick),
      role: "menuitem",
      className: g(Ue[`${We}__list__item`], {
        [Ue[`${We}__list__item--disabled`]]: $.disabled,
        [Ue[`${We}__list__item--with-divider`]]: $.withDivider
      })
    },
    $.element
  ));
  return /* @__PURE__ */ e.createElement(
    kr,
    {
      isVisible: u,
      placement: o,
      onClose: () => m(!1),
      triggerRenderer: () => /* @__PURE__ */ e.createElement(
        "button",
        {
          "data-testid": "action-menu-trigger-button",
          className: g(
            Ue[`${We}__trigger-button`],
            a
          ),
          onClick: E
        },
        _
      )
    },
    /* @__PURE__ */ e.createElement(
      "ul",
      A(w({}, n), {
        className: g(Ue[`${We}__list`], t),
        role: "menu",
        "aria-hidden": !u,
        ref: v
      }),
      r.map(N)
    )
  );
}, rt = {
  "action-menu-item": "lc-ActionMenuItem-module__action-menu-item___cT70J",
  "action-menu-item__left-node": "lc-ActionMenuItem-module__action-menu-item__left-node___3R-o1",
  "action-menu-item__right-node": "lc-ActionMenuItem-module__action-menu-item__right-node___uq0Ym",
  "action-menu-item__label": "lc-ActionMenuItem-module__action-menu-item__label___OgJ80",
  "action-menu-item--warning": "lc-ActionMenuItem-module__action-menu-item--warning___DgeYg"
}, _t = "action-menu-item", Er = ({ leftNode: t, rightNode: a, children: r, kind: _ }) => /* @__PURE__ */ e.createElement(
  "div",
  {
    className: g(rt[_t], {
      [rt[`${_t}--warning`]]: _ === "warning"
    })
  },
  t && /* @__PURE__ */ e.createElement("div", { className: rt[`${_t}__left-node`] }, t),
  /* @__PURE__ */ e.createElement(P, { as: "div", className: rt[`${_t}__label`] }, r),
  a && /* @__PURE__ */ e.createElement("div", { className: rt[`${_t}__right-node`] }, a)
), $r = "lc-Icon-module__icon___tw31R", sa = {
  icon: $r,
  "icon--primary": "lc-Icon-module__icon--primary___JJnBV",
  "icon--subtle": "lc-Icon-module__icon--subtle___sZIzL",
  "icon--inverted": "lc-Icon-module__icon--inverted___zA9QC",
  "icon--inverted-subtle": "lc-Icon-module__icon--inverted-subtle___6UwBT",
  "icon--link": "lc-Icon-module__icon--link___Jwkw9",
  "icon--success": "lc-Icon-module__icon--success___MKdz3",
  "icon--warning": "lc-Icon-module__icon--warning___5YCSW",
  "icon--error": "lc-Icon-module__icon--error___CiJCD",
  "icon--negative": "lc-Icon-module__icon--negative___vfgpo",
  "icon--positive": "lc-Icon-module__icon--positive___ulUuj",
  "icon--info": "lc-Icon-module__icon--info___Lfxfs",
  "icon--action-primary": "lc-Icon-module__icon--action-primary___n5UhF",
  "icon--action-negative": "lc-Icon-module__icon--action-negative___f1EPS",
  "icon--action-positive": "lc-Icon-module__icon--action-positive___o-6Q-",
  "icon--action-warning": "lc-Icon-module__icon--action-warning___zik06",
  "icon--action-neutral": "lc-Icon-module__icon--action-neutral___sTHAF",
  "icon--disabled--primary": "lc-Icon-module__icon--disabled--primary___E0nee",
  "icon--disabled--subtle": "lc-Icon-module__icon--disabled--subtle___l-hK4",
  "icon--disabled--inverted": "lc-Icon-module__icon--disabled--inverted___5SBAf",
  "icon--disabled--inverted-subtle": "lc-Icon-module__icon--disabled--inverted-subtle___w-NBk",
  "icon--disabled--link": "lc-Icon-module__icon--disabled--link___sVJOa",
  "icon--disabled--success": "lc-Icon-module__icon--disabled--success___sG-7d",
  "icon--disabled--warning": "lc-Icon-module__icon--disabled--warning___8ko1e",
  "icon--disabled--error": "lc-Icon-module__icon--disabled--error___9KbVo",
  "icon--disabled--negative": "lc-Icon-module__icon--disabled--negative___AzzCW",
  "icon--disabled--positive": "lc-Icon-module__icon--disabled--positive___JelzZ",
  "icon--disabled--info": "lc-Icon-module__icon--disabled--info___3xl-B",
  "icon--disabled--action-primary": "lc-Icon-module__icon--disabled--action-primary___2k1TG",
  "icon--disabled--action-negative": "lc-Icon-module__icon--disabled--action-negative___Oraj8",
  "icon--disabled--action-positive": "lc-Icon-module__icon--disabled--action-positive___zaLja",
  "icon--disabled--action-warning": "lc-Icon-module__icon--disabled--action-warning___5VvCf",
  "icon--disabled--action-neutral": "lc-Icon-module__icon--disabled--action-neutral___SxlyC"
}, wr = {
  xsmall: {
    width: 12,
    height: 12
  },
  small: {
    width: 16,
    height: 16
  },
  medium: {
    width: 20,
    height: 20
  },
  large: {
    width: 24,
    height: 24
  },
  xlarge: {
    width: 32,
    height: 32
  }
}, ia = "icon", D = (t) => {
  const u = t, {
    source: a,
    size: r = "medium",
    kind: _,
    disabled: o,
    className: l,
    customColor: c
  } = u, n = B(u, [
    "source",
    "size",
    "kind",
    "disabled",
    "className",
    "customColor"
  ]), s = e.createElement(a, A(w({}, wr[r]), {
    color: c
  })), i = g(
    l,
    sa[ia],
    _ && sa[`${ia}--${o ? "disabled--" : ""}${_}`]
  );
  return /* @__PURE__ */ e.createElement("span", A(w({}, n), { className: i }), s);
}, Cr = "lc-Loader-module__loader___LRflD", Nr = "lc-Loader-module__loader__spinner___l3C1g", xr = "lc-Loader-module__rotate___Tlni-", Sr = "lc-Loader-module__loader__label___R74f6", ot = {
  loader: Cr,
  loader__spinner: Nr,
  "loader__spinner--small": "lc-Loader-module__loader__spinner--small___dGFFt",
  "loader__spinner--medium": "lc-Loader-module__loader__spinner--medium___J-ki0",
  "loader__spinner--large": "lc-Loader-module__loader__spinner--large___Poqq7",
  "loader__spinner-circle": "lc-Loader-module__loader__spinner-circle___ApBUO",
  rotate: xr,
  loader__label: Sr
}, Ha = "loader", da = `${Ha}__spinner`, aa = ({
  primaryColor: t,
  secondaryColor: a,
  label: r,
  className: _,
  size: o = "medium"
}) => /* @__PURE__ */ e.createElement("div", { className: g(ot[Ha], _) }, /* @__PURE__ */ e.createElement(
  "div",
  {
    className: g(ot[da], ot[`${da}--${o}`])
  },
  /* @__PURE__ */ e.createElement(
    "div",
    {
      className: ot["loader__spinner-circle"],
      style: {
        /* stylelint-disable */
        borderColor: a,
        borderTopColor: t
      }
    }
  )
), r && /* @__PURE__ */ e.createElement(P, { as: "div", size: "md", className: ot.loader__label }, r)), Tr = "lc-Button-module__btn___aijZJ", Dr = "lc-Button-module__btn__loader___c8zZz", Ir = "lc-Button-module__btn__icon___GVExB", _e = {
  btn: Tr,
  "btn--disabled": "lc-Button-module__btn--disabled___lXBav",
  "btn--full-width": "lc-Button-module__btn--full-width___kdbAr",
  "btn--basic": "lc-Button-module__btn--basic___Jc2gD",
  "btn--primary": "lc-Button-module__btn--primary___ajbjQ",
  "btn--secondary": "lc-Button-module__btn--secondary___iWzE0",
  "btn--destructive": "lc-Button-module__btn--destructive___-kLCl",
  "btn--destructive-outline": "lc-Button-module__btn--destructive-outline___dexc3",
  "btn--compact": "lc-Button-module__btn--compact___4eptQ",
  "btn--icon-only": "lc-Button-module__btn--icon-only___bF5pW",
  "btn--large": "lc-Button-module__btn--large___lb-z6",
  "btn--text": "lc-Button-module__btn--text___WA8OL",
  "btn--link": "lc-Button-module__btn--link___51ZgF",
  "btn--link-light": "lc-Button-module__btn--link-light___iitZ6",
  "btn--plain": "lc-Button-module__btn--plain___eOPui",
  "btn--float": "lc-Button-module__btn--float___J4lTs",
  "btn--dotted": "lc-Button-module__btn--dotted___y1EEP",
  "btn--high-contrast": "lc-Button-module__btn--high-contrast___zHSVM",
  "btn--loading": "lc-Button-module__btn--loading___ZAgjv",
  btn__loader: Dr,
  btn__icon: Ir,
  "btn__icon--left": "lc-Button-module__btn__icon--left___H68zu",
  "btn__icon--right": "lc-Button-module__btn__icon--right___fHr3D",
  "btn--icon-only--bg": "lc-Button-module__btn--icon-only--bg___X-4V2"
}, oe = "btn", M = e.forwardRef(
  (p, f) => {
    var b = p, {
      loading: t = !1,
      disabled: a = !1,
      type: r = "button",
      fullWidth: _ = !1,
      kind: o = "basic",
      size: l = "medium",
      icon: c = null,
      iconPosition: n = "left",
      loaderLabel: s,
      className: i,
      children: u,
      href: m,
      onClick: d
    } = b, v = B(b, [
      "loading",
      "disabled",
      "type",
      "fullWidth",
      "kind",
      "size",
      "icon",
      "iconPosition",
      "loaderLabel",
      "className",
      "children",
      "href",
      "onClick"
    ]);
    const E = t || a, h = !u && c, N = ["text", "link", "link-light"].includes(o), $ = m ? "a" : "button", y = () => {
      if (o === "primary" || o === "destructive")
        return {
          primaryColor: "var(--action-primary-default)",
          secondaryColor: "var(--border-invert-primary)"
        };
    }, k = g(
      i,
      _e[oe],
      _e[`${oe}--${o}`],
      _e[`${oe}--${l}`],
      {
        [_e[`${oe}--loading`]]: t,
        [_e[`${oe}--full-width`]]: _,
        [_e[`${oe}--icon-only`]]: h,
        [_e[`${oe}--icon-only--bg`]]: h && N,
        [_e[`${oe}--disabled`]]: E
      }
    );
    return /* @__PURE__ */ e.createElement(
      $,
      w({
        ref: f,
        className: k,
        "aria-disabled": E,
        type: r,
        href: E ? void 0 : m,
        onClick: E ? void 0 : d,
        disabled: E
      }, v),
      t && /* @__PURE__ */ e.createElement(
        aa,
        w({
          size: "small",
          label: s,
          className: _e[`${oe}__loader`]
        }, y())
      ),
      c && e.cloneElement(c, {
        className: g(
          _e[`${oe}__icon`],
          _e[`${oe}__icon--${n}`]
        ),
        disabled: a
      }),
      /* @__PURE__ */ e.createElement("div", { className: _e[`${oe}__content`] }, u)
    );
  }
), Ar = "lc-Tooltip-module__tooltip___QOOAr", Br = "lc-Tooltip-module__tooltip__wrapper___dmjmW", Pr = "lc-Tooltip-module__tooltip__interactive___0JawB", Fr = "lc-Tooltip-module__tooltip__arrow___Ov1YW", S = {
  tooltip: Ar,
  tooltip__wrapper: Br,
  tooltip__interactive: Pr,
  "tooltip__user-guide-step": "lc-Tooltip-module__tooltip__user-guide-step___evexI",
  "tooltip--invert": "lc-Tooltip-module__tooltip--invert___PVjhE",
  "tooltip--important": "lc-Tooltip-module__tooltip--important___X-IMK",
  "tooltip--full-space": "lc-Tooltip-module__tooltip--full-space___wmbaw",
  tooltip__arrow: Fr,
  "tooltip-header": "lc-Tooltip-module__tooltip-header___QV0Vd",
  "tooltip-header--info": "lc-Tooltip-module__tooltip-header--info___zLJ2Z",
  "tooltip-image-container": "lc-Tooltip-module__tooltip-image-container___ySmcd",
  "tooltip-image": "lc-Tooltip-module__tooltip-image___Aorwg",
  "tooltip-text": "lc-Tooltip-module__tooltip-text___FB8OV",
  "tooltip-text--info": "lc-Tooltip-module__tooltip-text--info___3d2Ql",
  "tooltip-close": "lc-Tooltip-module__tooltip-close___3r3h9",
  "tooltip-step": "lc-Tooltip-module__tooltip-step___-j0n8",
  "tooltip-footer": "lc-Tooltip-module__tooltip-footer___9hxFq",
  "tooltip-footer-secondary": "lc-Tooltip-module__tooltip-footer-secondary___WBgVR",
  "tooltip-footer-secondary-invert": "lc-Tooltip-module__tooltip-footer-secondary-invert___PuAQL",
  "tooltip-footer--interactive": "lc-Tooltip-module__tooltip-footer--interactive___hqMM5",
  "tooltip-footer--user-guide-step": "lc-Tooltip-module__tooltip-footer--user-guide-step___i7z3S",
  "guide-tooltip--slide": "lc-Tooltip-module__guide-tooltip--slide___Uawm2",
  "guide-tooltip__overlay": "lc-Tooltip-module__guide-tooltip__overlay___IyrKK",
  "guide-tooltip__overlay--slide": "lc-Tooltip-module__guide-tooltip__overlay--slide___VW8oq",
  "guide-tooltip__overlay--visible": "lc-Tooltip-module__guide-tooltip__overlay--visible___dbdsZ",
  "reports-tooltip": "lc-Tooltip-module__reports-tooltip___amyTo",
  "reports-tooltip__heading": "lc-Tooltip-module__reports-tooltip__heading___FtjvD",
  "reports-tooltip__heading__title": "lc-Tooltip-module__reports-tooltip__heading__title___6TlzE",
  "reports-tooltip__heading__description": "lc-Tooltip-module__reports-tooltip__heading__description___eOQnc",
  "reports-tooltip__content": "lc-Tooltip-module__reports-tooltip__content___jxis9"
}, Rr = ({
  visible: t,
  floatingOptions: a,
  referenceElement: r,
  arrowOffsetX: _,
  arrowOffsetY: o,
  baseClass: l,
  className: c,
  theme: n,
  withFadeAnimation: s,
  transitionDuration: i,
  transitionDelay: u,
  handleMouseEnter: m,
  handleMouseLeave: d,
  handleCloseAction: v,
  arrowRef: f,
  childrenElements: p,
  fullSpaceContent: b
}) => {
  const {
    x: E,
    y: h,
    reference: N,
    floating: $,
    strategy: y,
    update: k,
    refs: C,
    placement: x,
    middlewareData: { arrow: { x: H, y: re } = {} }
  } = a;
  e.useEffect(() => {
    if (!(!C.reference.current || !C.floating.current))
      return Fa(C.reference.current, C.floating.current, k);
  }, [C.reference, C.floating, k, x, t]), e.useEffect(() => {
    r && N(r);
  }, [N, r]);
  const ne = o && re ? re + o : re, Oe = _ && H ? H + _ : H, He = g(S[l], c, {
    [S[`${l}--invert`]]: n === "invert",
    [S[`${l}--important`]]: n === "important",
    [S[`${l}--full-space`]]: b
  }), De = /* @__PURE__ */ e.createElement(
    "div",
    {
      ref: $,
      style: {
        position: y,
        top: h != null ? h : "",
        left: E != null ? E : ""
      },
      className: He,
      onMouseEnter: m,
      onMouseLeave: d
    },
    e.Children.map(p, (ye) => e.isValidElement(ye) ? e.cloneElement(ye, w({
      handleCloseAction: v,
      theme: n
    }, ye.props)) : null),
    /* @__PURE__ */ e.createElement(
      "div",
      {
        ref: f,
        className: g([S[`${l}__arrow`]]),
        "data-arrow-placement": x,
        style: { top: ne, left: Oe }
      }
    )
  );
  function ze() {
    if (s) {
      const ye = at`
        pointer-events: none;
        opacity: 0;
      `, Ge = at`
        opacity: 1;
        transition-property: opacity;
        transition-duration: ${i}ms;
        transition-delay: ${u}ms;
      `, se = at`
        pointer-events: initial;
      `, F = at`
        opacity: 1;
      `, U = at`
        opacity: 0;
        transition-property: opacity;
        transition-duration: ${i}ms;
        transition-delay: ${u}ms;
      `, q = i + u;
      return /* @__PURE__ */ e.createElement(
        ea,
        {
          in: t,
          mountOnEnter: !0,
          unmountOnExit: !0,
          timeout: q,
          classNames: {
            enter: ye,
            enterDone: se,
            enterActive: Ge,
            exit: F,
            exitActive: U
          }
        },
        De
      );
    } else
      return t && De;
  }
  return /* @__PURE__ */ e.createElement(e.Fragment, null, ze());
};
function za(t) {
  switch (t) {
    case "invert":
      return "inverted";
    default:
      return "primary";
  }
}
const Mr = (t) => new Promise((a) => setTimeout(a, t)), ua = "tooltip", Ga = (t) => {
  const {
    triggerRenderer: a,
    referenceElement: r,
    children: _,
    className: o,
    triggerClassName: l,
    theme: c,
    placement: n,
    isVisible: s,
    withFadeAnimation: i = !0,
    transitionDuration: u = 200,
    transitionDelay: m = 0,
    hoverOutDelayTimeout: d = 100,
    offsetMainAxis: v = 8,
    triggerOnClick: f = !1,
    arrowOffsetY: p,
    arrowOffsetX: b,
    fullSpaceContent: E,
    onOpen: h,
    onClose: N
  } = t, $ = e.useRef(!0), y = s !== void 0, k = e.useRef(null), [C, x] = e.useState(s), H = e.useRef(!1), re = Aa({
    middleware: [
      Ba({ mainAxis: v }),
      sr({ element: k }),
      Pa()
    ],
    placement: n
  }), ne = (se) => {
    se ? !C && (h == null || h()) : C && (N == null || N()), y || x(se);
  };
  e.useEffect(() => {
    if ($.current) {
      $.current = !1;
      return;
    }
    s === !0 && (h == null || h()), s === !1 && (N == null || N()), x(s);
  }, [s]), e.useEffect(() => (document.addEventListener("keydown", De), () => {
    document.removeEventListener("keydown", De);
  }), []);
  const Oe = () => {
    f || y || (H.current = !0, ne(!0));
  }, He = () => {
    f || y || (H.current = !1, Mr(d).then(() => {
      H.current || ne(!1);
    }));
  }, De = (se) => {
    (se instanceof KeyboardEvent && se.key === "Escape" || se.type === "click") && ne(!1);
  }, ze = /* @__PURE__ */ e.createElement(
    Rr,
    {
      baseClass: ua,
      className: o,
      visible: C,
      floatingOptions: re,
      arrowRef: k,
      handleMouseEnter: Oe,
      handleMouseLeave: He,
      handleCloseAction: De,
      childrenElements: _,
      transitionDuration: u,
      transitionDelay: m,
      referenceElement: r,
      arrowOffsetX: b,
      arrowOffsetY: p,
      theme: c,
      withFadeAnimation: i,
      fullSpaceContent: E
    }
  );
  if (r)
    return ze;
  const ye = () => {
    ne(!C);
  }, Ge = () => {
    if (!y)
      return f ? {
        onClick: ye
      } : {
        onMouseEnter: Oe,
        onMouseLeave: He
      };
  };
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
    "div",
    w({
      className: g(S[`${ua}__wrapper`], l),
      ref: re.reference
    }, Ge()),
    a()
  ), ze);
}, gl = ({ text: t }) => /* @__PURE__ */ e.createElement("div", null, t), lt = "tooltip", fl = ({ header: t, text: a, closeWithX: r, theme: _, handleCloseAction: o }) => /* @__PURE__ */ e.createElement("div", null, r && /* @__PURE__ */ e.createElement(
  M,
  {
    size: "compact",
    kind: "plain",
    className: S[`${lt}-close`],
    onClick: o,
    icon: /* @__PURE__ */ e.createElement(
      D,
      {
        source: Ma,
        kind: _ ? za(_) : "primary"
      }
    )
  }
), t && /* @__PURE__ */ e.createElement(
  "div",
  {
    className: g(
      S[`${lt}-header`],
      S[`${lt}-header--info`]
    )
  },
  t
), /* @__PURE__ */ e.createElement(
  "div",
  {
    className: g(
      S[`${lt}-text`],
      S[`${lt}-text--info`]
    )
  },
  a
)), ge = "tooltip", bl = ({
  header: t,
  text: a,
  image: r,
  closeWithX: _,
  theme: o,
  handleCloseAction: l,
  primaryButton: c,
  secondaryButton: n
}) => {
  const s = (i) => i === "invert" ? "secondary" : "high-contrast";
  return /* @__PURE__ */ e.createElement("div", { className: S[`${ge}__interactive`] }, _ && /* @__PURE__ */ e.createElement(
    M,
    {
      className: S[`${ge}-close`],
      size: "compact",
      kind: "plain",
      onClick: l,
      icon: /* @__PURE__ */ e.createElement(
        D,
        {
          source: Ma,
          kind: o ? za(o) : "primary"
        }
      )
    }
  ), r && /* @__PURE__ */ e.createElement("div", { className: S[`${ge}-image-container`] }, /* @__PURE__ */ e.createElement(
    "img",
    {
      className: S[`${ge}-image`],
      src: r.src,
      alt: r.alt
    }
  )), t && /* @__PURE__ */ e.createElement("div", { className: S[`${ge}-header`] }, t), /* @__PURE__ */ e.createElement("div", { className: S[`${ge}-text`] }, a), /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g(
        S[`${ge}-footer`],
        S[`${ge}-footer--interactive`]
      )
    },
    /* @__PURE__ */ e.createElement(
      M,
      {
        kind: c.kind || s(o),
        onClick: c.handleClick
      },
      c.label
    ),
    /* @__PURE__ */ e.createElement(
      M,
      {
        className: g(S[`${ge}-footer-secondary`], {
          [S[`${ge}-footer-secondary-invert`]]: o === "invert"
        }),
        kind: n.kind || "text",
        onClick: n.handleClick
      },
      n.label
    )
  ));
}, J = "guide-tooltip", Lr = ({
  gap: t,
  isVisible: a,
  slide: r,
  disablePointerEvents: _
}) => {
  if (!t)
    return null;
  const o = {
    top: `${t.top}px`,
    left: "0",
    width: `${t.left}px`,
    height: `${t.height}px`
  }, l = {
    top: `${t.top}px`,
    left: `${t.right}px`,
    width: `calc(100% - ${t.right}px)`,
    height: `${t.height}px`
  }, c = {
    top: "0",
    left: "0",
    width: "100%",
    height: `${t.top}px`
  }, n = {
    top: `${t.bottom}px`,
    left: "0",
    width: "100%",
    height: `calc(100% - ${t.bottom}px)`
  }, s = {
    top: `${t.top}px`,
    left: `${t.left}px`,
    width: `${t.width}px`,
    height: `${t.height}px`,
    backgroundColor: "transparent"
  };
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g({
        [S[`${J}__overlay`]]: !0,
        [S[`${J}__overlay--visible`]]: a,
        [S[`${J}__overlay--slide`]]: r
      }),
      style: o
    }
  ), /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g({
        [S[`${J}__overlay`]]: !0,
        [S[`${J}__overlay--visible`]]: a,
        [S[`${J}__overlay--slide`]]: r
      }),
      style: c
    }
  ), /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g({
        [S[`${J}__overlay`]]: !0,
        [S[`${J}__overlay--visible`]]: a,
        [S[`${J}__overlay--slide`]]: r
      }),
      style: l
    }
  ), /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g({
        [S[`${J}__overlay`]]: !0,
        [S[`${J}__overlay--visible`]]: a,
        [S[`${J}__overlay--slide`]]: r
      }),
      style: n
    }
  ), _ && /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g({
        [S[`${J}__overlay`]]: !0,
        [S[`${J}__overlay--visible`]]: a,
        [S[`${J}__overlay--slide`]]: r
      }),
      style: s
    }
  ));
};
class Or {
  constructor(a, r) {
    Ot(this, "element");
    Ot(this, "padding");
    this.element = a, this.padding = r;
  }
  addPadding(a) {
    const r = Math.round(a.left) - this.padding, _ = Math.round(a.top) - this.padding, o = Math.round(a.width) + 2 * this.padding, l = Math.round(a.height) + 2 * this.padding, c = _, n = r, s = c + l, i = n + o;
    return {
      x: r,
      y: _,
      width: o,
      height: l,
      top: c,
      left: n,
      bottom: s,
      right: i
    };
  }
  getBoundingClientRect() {
    return this.addPadding(this.element.getBoundingClientRect());
  }
  get clientWidth() {
    return this.getBoundingClientRect().width;
  }
  get clientHeight() {
    return this.getBoundingClientRect().height;
  }
}
const ma = 8, pa = "guide-tooltip", ga = (t, a) => new Or(t, a), vl = (t) => {
  const {
    className: a,
    parentElementName: r,
    isVisible: _ = !1,
    shouldSlide: o = !0
  } = t, [l, c] = e.useState(
    null
  ), [n, s] = e.useState(null), [i, u] = e.useState(o), m = () => {
    l && (s(
      ga(
        l,
        ma
      ).getBoundingClientRect()
    ), u(!1));
  };
  return e.useEffect(() => {
    if (l !== null)
      return window.addEventListener("resize", m), window.addEventListener("scroll", m), () => {
        window.addEventListener("resize", m), window.addEventListener("resize", m);
      };
  }, [l, r]), e.useEffect(() => {
    if (r) {
      const d = document.querySelector(r);
      c(d);
    }
  }, [r]), e.useEffect(() => {
    l && s(
      ga(
        l,
        ma
      ).getBoundingClientRect()
    ), u(!0);
  }, [l]), l && _ ? /* @__PURE__ */ e.createElement("div", null, /* @__PURE__ */ e.createElement(
    Lr,
    {
      gap: n,
      isVisible: _,
      slide: i,
      disablePointerEvents: !0
    }
  ), /* @__PURE__ */ e.createElement(
    Ga,
    A(w({}, t), {
      referenceElement: {
        getBoundingClientRect: () => n,
        contextElement: l
      },
      arrowOffsetY: 25,
      className: g({
        [S[pa]]: !0,
        [S[`${pa}--slide`]]: i,
        className: a
      })
    }),
    t.children
  )) : null;
}, ct = "reports-tooltip", hl = ({
  title: t,
  description: a,
  children: r
}) => /* @__PURE__ */ e.createElement("div", { className: S[ct] }, /* @__PURE__ */ e.createElement("div", { className: S[`${ct}__heading`] }, /* @__PURE__ */ e.createElement(P, { as: "div", className: S[`${ct}__heading__title`] }, t), /* @__PURE__ */ e.createElement(
  P,
  {
    as: "div",
    size: "xs",
    className: S[`${ct}__heading__description`]
  },
  a
)), /* @__PURE__ */ e.createElement("div", { className: S[`${ct}__content`] }, /* @__PURE__ */ e.createElement(P, { as: "div" }, r))), Se = {
  "action-bar": "lc-ActionBar-module__action-bar___TJitF",
  "action-bar__items": "lc-ActionBar-module__action-bar__items___uhoQA",
  "action-bar__items--scroll": "lc-ActionBar-module__action-bar__items--scroll___f--dN",
  "action-bar__items__button": "lc-ActionBar-module__action-bar__items__button___Vdjl7",
  "action-bar__items__button--active": "lc-ActionBar-module__action-bar__items__button--active___PFLdm",
  "action-bar__items__button--hidden": "lc-ActionBar-module__action-bar__items__button--hidden___C00VA",
  "action-bar__items__tooltip": "lc-ActionBar-module__action-bar__items__tooltip___BuQPo",
  "action-bar__menu-wrapper": "lc-ActionBar-module__action-bar__menu-wrapper___OMWP5"
}, yt = "action-bar__items", Hr = ({
  option: t,
  menuItemsKeys: a,
  activeOptionKey: r
}) => {
  const _ = g(Se[`${yt}__button`], {
    [Se[`${yt}__button--hidden`]]: a.includes(
      t.key
    ),
    [Se[`${yt}__button--active`]]: t.key === r
  });
  if (t.showTooltip) {
    const o = a.includes(t.key) && {
      isVisible: !1
    };
    return /* @__PURE__ */ e.createElement(
      Ga,
      w({
        theme: "invert",
        placement: "top",
        triggerClassName: Se[`${yt}__tooltip`],
        triggerRenderer: () => /* @__PURE__ */ e.createElement(
          M,
          {
            id: t.key,
            key: t.key,
            title: t.label,
            kind: "plain",
            className: _,
            onClick: t.onClick,
            icon: t.element
          }
        )
      }, o),
      /* @__PURE__ */ e.createElement("div", null, t.label)
    );
  }
  return /* @__PURE__ */ e.createElement(
    M,
    {
      id: t.key,
      key: t.key,
      title: t.label,
      kind: "plain",
      className: _,
      onClick: t.onClick,
      icon: t.element
    }
  );
}, nt = "action-bar", kl = ({
  className: t,
  id: a = "action-bar-area",
  type: r = "menu",
  options: _,
  activeOptionKey: o
}) => {
  const [l, c] = e.useState([]), n = r === "scroll", s = g(Se[nt], t), i = {
    root: document.querySelector(`${a}`),
    threshold: 1
  }, u = !n && l.length !== 0, m = (v) => {
    v.map((f) => {
      const p = l.includes(f.target.id);
      if (!f.isIntersecting) {
        f.target.setAttribute("tabindex", "-1"), p || c((b) => [
          ...b,
          f.target.id
        ]);
        return;
      }
      f.target.removeAttribute("tabindex"), p && c(l.filter((b) => b !== f.target.id));
    });
  };
  e.useEffect(() => {
    const v = !!window.IntersectionObserver;
    if (!n && v) {
      const f = document.querySelectorAll(
        `.${Se[`${nt}__items__button`]}`
      ), p = new IntersectionObserver(
        m,
        i
      );
      return f.forEach((b) => p.observe(b)), () => p.disconnect();
    }
  }, [l, n]);
  const d = (v) => _.filter(
    (p) => v.find((b) => b === p.key)
  ).map((p) => ({
    key: p.key,
    element: /* @__PURE__ */ e.createElement(Er, { leftNode: p.element }, p.label),
    onClick: p.onClick
  }));
  return /* @__PURE__ */ e.createElement("div", { id: a, className: s }, /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g(Se[`${nt}__items`], {
        [Se[`${nt}__items--scroll`]]: n
      })
    },
    _.map((v) => /* @__PURE__ */ e.createElement(
      Hr,
      {
        option: v,
        menuItemsKeys: l,
        activeOptionKey: o
      }
    ))
  ), u && /* @__PURE__ */ e.createElement("div", { className: Se[`${nt}__menu-wrapper`] }, /* @__PURE__ */ e.createElement(
    yr,
    {
      options: d(l),
      triggerRenderer: /* @__PURE__ */ e.createElement(D, { source: bt, kind: "primary" })
    }
  )));
}, zr = "lc-Alert-module__alert___HBTns", Gr = "lc-Alert-module__alert__icon___8TaAH", Ur = "lc-Alert-module__alert__content___9nZdf", Wr = "lc-Alert-module__alert__content__text___7nx1p", Vr = "lc-Alert-module__alert__content__cta___gTWPt", Yr = "lc-Alert-module__alert__content__cta__link___D976B", fe = {
  alert: zr,
  alert__icon: Gr,
  alert__content: Ur,
  alert__content__text: Wr,
  alert__content__cta: Vr,
  alert__content__cta__link: Yr,
  "alert__close-icon": "lc-Alert-module__alert__close-icon___rYxVy",
  "alert--small": "lc-Alert-module__alert--small___Fyk-v",
  "alert--medium": "lc-Alert-module__alert--medium___ItZWT",
  "alert--info": "lc-Alert-module__alert--info___GmIGN",
  "alert--warning": "lc-Alert-module__alert--warning___MubhD",
  "alert--success": "lc-Alert-module__alert--success___fPtlZ",
  "alert--error": "lc-Alert-module__alert--error___DKPo0"
}, qr = {
  info: {
    source: Zt,
    kind: "link"
  },
  warning: {
    source: Ta,
    kind: "warning"
  },
  success: {
    source: Da,
    kind: "success"
  },
  error: {
    source: Ia,
    kind: "error"
  }
}, be = "alert", yl = (n) => {
  var s = n, {
    children: t,
    className: a,
    primaryButton: r,
    secondaryButton: _,
    kind: o = "info",
    onClose: l
  } = s, c = B(s, [
    "children",
    "className",
    "primaryButton",
    "secondaryButton",
    "kind",
    "onClose"
  ]);
  const i = e.useRef(null), [u, m] = e.useState(null), d = g(
    fe[be],
    fe[`${be}--${o}`],
    u === "medium" && fe[`${be}--medium`],
    u === "small" && fe[`${be}--small`],
    a
  );
  return e.useEffect(() => {
    const v = () => i.current && i.current.offsetWidth <= 400 ? m("small") : i.current && i.current.offsetWidth > 400 && i.current.offsetWidth <= 800 ? m("medium") : m(null), f = La(() => {
      v();
    }, 500);
    return window.addEventListener("resize", f), v(), () => window.removeEventListener("resize", f);
  }), /* @__PURE__ */ e.createElement("div", w({ ref: i, className: d }, c), /* @__PURE__ */ e.createElement(
    D,
    A(w({}, qr[o]), {
      size: "large",
      className: fe[`${be}__icon`]
    })
  ), /* @__PURE__ */ e.createElement("div", { className: fe[`${be}__content`] }, /* @__PURE__ */ e.createElement(P, { as: "div", className: fe[`${be}__content__text`] }, t), (r || _) && /* @__PURE__ */ e.createElement("div", { className: fe[`${be}__content__cta`] }, r && /* @__PURE__ */ e.createElement(M, { kind: "high-contrast", onClick: r.handleClick }, r.label), _ && /* @__PURE__ */ e.createElement(
    M,
    {
      className: fe[`${be}__content__cta__link`],
      kind: "text",
      onClick: _.handleClick
    },
    _.label
  ))), l && /* @__PURE__ */ e.createElement(
    M,
    {
      type: "button",
      className: fe[`${be}__close-icon`],
      size: "compact",
      kind: "plain",
      icon: /* @__PURE__ */ e.createElement(D, { source: Te }),
      onClick: l
    }
  ));
}, fa = Array.from(
  { length: 10 },
  (t, a) => `--surface-avatar-${a + 1}`
);
function Jr(t) {
  if (!t)
    return;
  const a = t.split("").reduce((r, _) => r + _.charCodeAt(0), 0);
  return `var(${fa[a % fa.length]})`;
}
function Kr(t = "", a = 2) {
  const r = t.split(/\s+/);
  if (a < 1)
    return "";
  if (r.length <= a)
    return r.map((o) => o.charAt(0)).join("").toUpperCase();
  const _ = [r[0].charAt(0)];
  for (let o = r.length - a + 1; o < r.length; o++)
    _.push(r[o].charAt(0));
  return _.join("").toUpperCase();
}
function jr(t) {
  let a = t;
  if (t.startsWith("var(--") && t.endsWith(")")) {
    const r = t.slice(4, -1);
    a = window.getComputedStyle(document.documentElement).getPropertyValue(r).trim();
  }
  return a && ta(a, "#FFFFFF") > 3 ? "var(--color-white)" : "var(--color-black)";
}
const Qr = "lc-Avatar-module__avatar___1zTCE", Zr = "lc-Avatar-module__avatar__status___nX2Ls", Xr = "lc-Avatar-module__avatar__rim___RCQ8Q", e_ = "lc-Avatar-module__avatar__image___YqfqE", ee = {
  avatar: Qr,
  avatar__status: Zr,
  "avatar__status--available": "lc-Avatar-module__avatar__status--available___tcpFg",
  "avatar__status--unavailable": "lc-Avatar-module__avatar__status--unavailable___ciauF",
  "avatar__status--unknown": "lc-Avatar-module__avatar__status--unknown___MBaGB",
  "avatar__status--xxxsmall": "lc-Avatar-module__avatar__status--xxxsmall___8pkEO",
  "avatar__status--circle": "lc-Avatar-module__avatar__status--circle___doV5H",
  "avatar__status--xxsmall": "lc-Avatar-module__avatar__status--xxsmall___M69lx",
  "avatar__status--xsmall": "lc-Avatar-module__avatar__status--xsmall___RKQiI",
  "avatar__status--small": "lc-Avatar-module__avatar__status--small___cVrA7",
  "avatar__status--medium": "lc-Avatar-module__avatar__status--medium___n5z1H",
  "avatar__status--large": "lc-Avatar-module__avatar__status--large___Lt-0p",
  "avatar__status--xlarge": "lc-Avatar-module__avatar__status--xlarge___RWIcJ",
  "avatar__status--xxlarge": "lc-Avatar-module__avatar__status--xxlarge___crG9D",
  "avatar__status--xxxlarge": "lc-Avatar-module__avatar__status--xxxlarge___Iv61X",
  "avatar__status--rounded-square": "lc-Avatar-module__avatar__status--rounded-square___-My1P",
  avatar__rim: Xr,
  "avatar__rim--xxxsmall": "lc-Avatar-module__avatar__rim--xxxsmall___0CH9D",
  "avatar__rim--xxsmall": "lc-Avatar-module__avatar__rim--xxsmall___Mde0K",
  "avatar__rim--xsmall": "lc-Avatar-module__avatar__rim--xsmall___LAN-b",
  "avatar__rim--small": "lc-Avatar-module__avatar__rim--small___FT8LX",
  "avatar__rim--medium": "lc-Avatar-module__avatar__rim--medium___Dy0pr",
  "avatar__rim--large": "lc-Avatar-module__avatar__rim--large___FPvGp",
  "avatar__rim--xlarge": "lc-Avatar-module__avatar__rim--xlarge___CY0kN",
  "avatar__rim--xxlarge": "lc-Avatar-module__avatar__rim--xxlarge___tE053",
  "avatar__rim--xxxlarge": "lc-Avatar-module__avatar__rim--xxxlarge___amlGP",
  avatar__image: e_,
  "avatar__icon--xxxsmall": "lc-Avatar-module__avatar__icon--xxxsmall___KaoMa",
  "avatar__icon--xxsmall": "lc-Avatar-module__avatar__icon--xxsmall___NJJNV",
  "avatar__icon--xsmall": "lc-Avatar-module__avatar__icon--xsmall___yHEZK",
  "avatar__icon--small": "lc-Avatar-module__avatar__icon--small___l6lBu",
  "avatar__icon--medium": "lc-Avatar-module__avatar__icon--medium___LF4w2",
  "avatar__icon--large": "lc-Avatar-module__avatar__icon--large___QntBf",
  "avatar__icon--xlarge": "lc-Avatar-module__avatar__icon--xlarge___bcHbI",
  "avatar__icon--xxlarge": "lc-Avatar-module__avatar__icon--xxlarge___sx8Fq",
  "avatar__icon--xxxlarge": "lc-Avatar-module__avatar__icon--xxxlarge___4144S",
  "avatar--circle": "lc-Avatar-module__avatar--circle___XwEz-",
  "avatar--rounded-square": "lc-Avatar-module__avatar--rounded-square___m7-4I",
  "avatar--xxxsmall": "lc-Avatar-module__avatar--xxxsmall___EHzpP",
  "avatar--xxsmall": "lc-Avatar-module__avatar--xxsmall___EDOVp",
  "avatar--xsmall": "lc-Avatar-module__avatar--xsmall___nI348",
  "avatar--small": "lc-Avatar-module__avatar--small___UuQen",
  "avatar--medium": "lc-Avatar-module__avatar--medium___Bt9Eo",
  "avatar--large": "lc-Avatar-module__avatar--large___OU-08",
  "avatar--xlarge": "lc-Avatar-module__avatar--xlarge___zwgeW",
  "avatar--xxlarge": "lc-Avatar-module__avatar--xxlarge___Ur0ul",
  "avatar--xxxlarge": "lc-Avatar-module__avatar--xxxlarge___Z-54U"
}, Y = "avatar", t_ = ({
  alt: t,
  className: a,
  color: r,
  shape: _ = "circle",
  size: o = "medium",
  src: l,
  status: c,
  text: n,
  type: s,
  withRim: i = !1
}) => {
  const u = s === "image" && !l, [m, d] = e.useState(u), v = s === "image" && !!l && !m, f = s === "text", p = ["xxxsmall", "xxsmall", "xsmall"].includes(o) ? 1 : 2, b = Kr(n, p), E = r || Jr(n), h = E ? jr(E) : void 0, N = f ? { backgroundColor: E } : {}, $ = g(w({
    [ee[Y]]: !0,
    [ee[`${Y}--${_}`]]: !0,
    [ee[`${Y}--${o}`]]: !0,
    [ee[`${Y}--with-rim`]]: i
  }, a ? { [`${a}`]: a } : {})), y = g(
    ee[`${Y}__status`],
    ee[`${Y}__status--${_}`],
    ee[`${Y}__status--${o}`],
    ...c ? [ee[`${Y}__status--${c}`]] : []
  ), k = g(
    ee[`${Y}__icon`],
    ee[`${Y}__icon--${o}`]
  ), C = g(
    ee[`${Y}__rim`],
    ee[`${Y}__rim--${o}`]
  ), x = e.useCallback(() => d(!0), []);
  return e.useEffect(() => {
    d(u);
  }, [u]), /* @__PURE__ */ e.createElement("div", { className: $, style: N }, i && /* @__PURE__ */ e.createElement(
    "div",
    {
      "data-testid": `${Y}__rim`,
      className: C
    }
  ), c && /* @__PURE__ */ e.createElement(
    "div",
    {
      "data-testid": `${Y}__status`,
      className: y
    }
  ), v && /* @__PURE__ */ e.createElement(
    "img",
    {
      className: ee[`${Y}__image`],
      src: l,
      alt: t,
      onError: x
    }
  ), f && /* @__PURE__ */ e.createElement("span", { style: { color: h } }, b), m && /* @__PURE__ */ e.createElement(
    D,
    {
      "data-testid": `${Y}__icon`,
      className: k,
      source: ar
    }
  ));
};
function a_(t, a) {
  return t > a ? `${a}+` : `${t}`;
}
const r_ = "lc-Badge-module__badge___GhLnu", __ = "lc-Badge-module__badge__dot___z6RO6", Et = {
  badge: r_,
  badge__dot: __,
  "badge--large": "lc-Badge-module__badge--large___B131q",
  "badge--medium": "lc-Badge-module__badge--medium___TDU8a",
  "badge--compact": "lc-Badge-module__badge--compact___9xJ4y",
  "badge--primary": "lc-Badge-module__badge--primary___8LRpp",
  "badge--secondary": "lc-Badge-module__badge--secondary___JjNIJ",
  "badge--tertiary": "lc-Badge-module__badge--tertiary___e83wV"
}, $t = "badge", o_ = (n) => {
  var s = n, {
    className: t,
    count: a = 0,
    max: r = 99,
    kind: _ = "primary",
    size: o = "medium",
    type: l = "counter"
  } = s, c = B(s, [
    "className",
    "count",
    "max",
    "kind",
    "size",
    "type"
  ]);
  const i = g(
    t,
    Et[$t],
    Et[`${$t}--${_}`],
    Et[`${$t}--${o}`]
  ), u = {
    counter: a_(a, r),
    alert: "!",
    dot: /* @__PURE__ */ e.createElement("span", { className: Et[`${$t}__dot`] })
  }[l];
  return /* @__PURE__ */ e.createElement("span", w({ className: i }, c), u);
};
function l_() {
}
const c_ = "lc-SegmentedControl-module__btn___tfsvL", wt = {
  "segmented-control": "lc-SegmentedControl-module__segmented-control___LHWSk",
  btn: c_,
  "btn--active": "lc-SegmentedControl-module__btn--active___Za-th",
  "btn--compact": "lc-SegmentedControl-module__btn--compact___gcSwq",
  "btn--medium": "lc-SegmentedControl-module__btn--medium___w3UzC",
  "btn--large": "lc-SegmentedControl-module__btn--large___yHPw4"
}, n_ = "segmented-control", El = ({
  size: t = "medium",
  buttons: a,
  className: r,
  initialId: _,
  currentId: o,
  fullWidth: l = !1,
  onButtonClick: c = l_
}) => {
  const n = g(wt[n_], r), [s, i] = e.useState(() => _), u = typeof o == "string";
  e.useEffect(() => {
    u && i(o);
  }, [o]);
  const m = (v, f) => {
    u || i(v), c(v, f);
  }, d = a.map(({ id: v, label: f, loading: p, disabled: b, icon: E }) => {
    const h = v === s, N = h ? wt["btn--active"] : "", $ = h ? !1 : p;
    return /* @__PURE__ */ e.createElement(
      M,
      {
        key: v,
        fullWidth: l,
        loading: $,
        disabled: b,
        "aria-pressed": h,
        kind: "secondary",
        icon: E,
        className: g(wt.btn, wt[`btn--${t}`], N),
        onClick: (y) => {
          m(v, y);
        }
      },
      f
    );
  });
  return /* @__PURE__ */ e.createElement("div", { role: "group", className: n }, d);
}, s_ = "lc-Card-module__card___GqMm2", i_ = "lc-Card-module__card__header___0ptfD", d_ = "lc-Card-module__card__header__image___QMwSu", u_ = "lc-Card-module__card__header__heading___Wkikx", m_ = "lc-Card-module__card__header__heading__title___Uxi8s", p_ = "lc-Card-module__card__header__heading__description___6LnOK", g_ = "lc-Card-module__card__content___KuDcc", f_ = "lc-Card-module__card__actions___q9sAo", b_ = "lc-Card-module__card__actions__line___TF1bt", v_ = "lc-Card-module__card__actions__buttons___PDMMR", te = {
  card: s_,
  card__header: i_,
  card__header__image: d_,
  card__header__heading: u_,
  card__header__heading__title: m_,
  card__header__heading__description: p_,
  "card__header__no-image": "lc-Card-module__card__header__no-image___UcnNY",
  card__content: g_,
  "card__expanded-content": "lc-Card-module__card__expanded-content___yB-QL",
  card__actions: f_,
  card__actions__line: b_,
  card__actions__buttons: v_,
  "card__actions__buttons-expander": "lc-Card-module__card__actions__buttons-expander___lz4h-"
}, ft = "card", Pt = `${ft}__header`, zt = `${Pt}__heading`, Ct = `${ft}__actions`, h_ = `${Pt}__no-image`, $l = (i) => {
  var u = i, {
    alt: t,
    buttonsOptions: a = [],
    children: r,
    className: _,
    description: o,
    expandableContent: l,
    src: c,
    title: n
  } = u, s = B(u, [
    "alt",
    "buttonsOptions",
    "children",
    "className",
    "description",
    "expandableContent",
    "src",
    "title"
  ]);
  const [m, d] = e.useState(!1), v = m ? Ft : bt, f = m ? "Hide" : "Show more", p = (a == null ? void 0 : a.length) > 0, b = !!l, E = p || b, h = n, N = c, $ = N ? "" : te[h_];
  return /* @__PURE__ */ e.createElement("div", w({ className: g(te[ft], _) }, s), h && /* @__PURE__ */ e.createElement("div", { className: g(te[Pt], $) }, N && /* @__PURE__ */ e.createElement(
    "img",
    {
      alt: t,
      className: te[`${Pt}__image`],
      src: c
    }
  ), /* @__PURE__ */ e.createElement("div", { className: te[zt] }, /* @__PURE__ */ e.createElement(et, { size: "sm", className: te[`${zt}__title`] }, n), /* @__PURE__ */ e.createElement(P, { size: "sm", className: te[`${zt}__description`] }, o))), /* @__PURE__ */ e.createElement(P, { as: "div", size: "md", className: te[`${ft}__content`] }, r), m && /* @__PURE__ */ e.createElement(
    P,
    {
      as: "div",
      size: "md",
      className: te[`${ft}__expanded-content`]
    },
    l
  ), E && /* @__PURE__ */ e.createElement("div", { className: te[Ct] }, /* @__PURE__ */ e.createElement("div", { className: te[`${Ct}__line`] }), /* @__PURE__ */ e.createElement("div", { className: te[`${Ct}__buttons`] }, p && a.map(
    ({ kind: y, onClick: k, children: C }) => /* @__PURE__ */ e.createElement(M, { size: "compact", kind: y, onClick: k }, C)
  ), b && /* @__PURE__ */ e.createElement(
    M,
    {
      className: te[`${Ct}__buttons-expander`],
      kind: "link",
      iconPosition: "right",
      icon: /* @__PURE__ */ e.createElement(D, { source: v }),
      onClick: () => d(!m)
    },
    f
  ))));
}, k_ = {
  "field-description": "lc-FieldDescription-module__field-description___IcRDH"
}, y_ = "field-description", Rt = (_) => {
  var o = _, {
    children: t,
    className: a = ""
  } = o, r = B(o, [
    "children",
    "className"
  ]);
  const l = g(k_[y_], a);
  return /* @__PURE__ */ e.createElement(P, A(w({ as: "span", size: "sm" }, r), { className: l }), t);
}, E_ = "lc-Checkbox-module__checkbox___G7nTf", $_ = "lc-Checkbox-module__checkbox__label___-o01x", w_ = "lc-Checkbox-module__checkbox__text___P2SIq", C_ = "lc-Checkbox-module__checkbox__input___YLbVF", N_ = "lc-Checkbox-module__checkbox__checkmark___16pfY", x_ = "lc-Checkbox-module__checkbox__square___MHUyd", S_ = "lc-Checkbox-module__checkbox__helper___YSJ-n", Pe = {
  checkbox: E_,
  checkbox__label: $_,
  checkbox__text: w_,
  checkbox__input: C_,
  checkbox__checkmark: N_,
  "checkbox--selected": "lc-Checkbox-module__checkbox--selected___S2vqY",
  checkbox__square: x_,
  "checkbox--disabled": "lc-Checkbox-module__checkbox--disabled___-lAqW",
  checkbox__helper: S_
}, Fe = "checkbox", wl = e.forwardRef(
  (n, c) => {
    var s = n, { checked: t, disabled: a, children: r, description: _, className: o } = s, l = B(s, ["checked", "disabled", "children", "description", "className"]);
    return /* @__PURE__ */ e.createElement(
      "div",
      {
        className: g(Pe[Fe], o, {
          [Pe[`${Fe}--selected`]]: t,
          [Pe[`${Fe}--disabled`]]: a
        })
      },
      /* @__PURE__ */ e.createElement("label", { className: Pe[`${Fe}__label`] }, /* @__PURE__ */ e.createElement(
        "input",
        A(w({}, l), {
          ref: c,
          checked: t,
          disabled: a,
          className: Pe[`${Fe}__input`],
          type: "checkbox"
        })
      ), r && /* @__PURE__ */ e.createElement(P, { as: "div", size: "md", className: Pe[`${Fe}__text`] }, r)),
      _ && /* @__PURE__ */ e.createElement(Rt, { className: Pe[`${Fe}__helper`] }, _)
    );
  }
), T = {
  "date-picker": "lc-DatePicker-module__date-picker___9AqJ2",
  "date-picker--interaction-disabled": "lc-DatePicker-module__date-picker--interaction-disabled___-zn8o",
  "date-picker__day": "lc-DatePicker-module__date-picker__day___ZTSw9",
  "date-picker__day--disabled": "lc-DatePicker-module__date-picker__day--disabled___4O6yW",
  "date-picker__day--selected": "lc-DatePicker-module__date-picker__day--selected___1D1tC",
  "date-picker__day--outside": "lc-DatePicker-module__date-picker__day--outside___ptNdC",
  "date-picker__day-content": "lc-DatePicker-module__date-picker__day-content___rDIa0",
  "date-picker__wrapper": "lc-DatePicker-module__date-picker__wrapper___tNp4l",
  "date-picker__months": "lc-DatePicker-module__date-picker__months___QuPb0",
  "date-picker__month": "lc-DatePicker-module__date-picker__month___239nh",
  "date-picker__nav-bar": "lc-DatePicker-module__date-picker__nav-bar___u-l17",
  "date-picker__nav-button": "lc-DatePicker-module__date-picker__nav-button___1Ysdp",
  "date-picker__nav-button--interaction-disabled": "lc-DatePicker-module__date-picker__nav-button--interaction-disabled___Ivjrx",
  "date-picker__caption": "lc-DatePicker-module__date-picker__caption___XPgZJ",
  "date-picker__weekdays": "lc-DatePicker-module__date-picker__weekdays___kpTY9",
  "date-picker__weekdays-row": "lc-DatePicker-module__date-picker__weekdays-row___tGgay",
  "date-picker__weekday": "lc-DatePicker-module__date-picker__weekday___3FCx8",
  "date-picker__body": "lc-DatePicker-module__date-picker__body___toP1u",
  "date-picker__week": "lc-DatePicker-module__date-picker__week___nZI1x",
  "date-picker__day-wrapper": "lc-DatePicker-module__date-picker__day-wrapper___gGeeJ",
  "date-picker__day--today": "lc-DatePicker-module__date-picker__day--today___LL51c",
  "date-picker__footer": "lc-DatePicker-module__date-picker__footer___cXMQ5",
  "date-picker__input": "lc-DatePicker-module__date-picker__input___r6qih",
  "date-picker__input-overlay-wrapper": "lc-DatePicker-module__date-picker__input-overlay-wrapper___vmC0M",
  "date-picker__input-overlay": "lc-DatePicker-module__date-picker__input-overlay___eJ9D0",
  "date-picker--range": "lc-DatePicker-module__date-picker--range___ZU-7k",
  "date-picker__day--start": "lc-DatePicker-module__date-picker__day--start___-oB8U",
  "date-picker__day--end": "lc-DatePicker-module__date-picker__day--end___TtN5P",
  "date-picker__day--single": "lc-DatePicker-module__date-picker__day--single___biWcB",
  "date-picker__day--sunday": "lc-DatePicker-module__date-picker__day--sunday___JEnhV",
  "date-picker__day--monday": "lc-DatePicker-module__date-picker__day--monday___NXYIr",
  "date-picker--range__select-input": "lc-DatePicker-module__date-picker--range__select-input___6pyj3",
  "date-picker--range__calendars-wrapper": "lc-DatePicker-module__date-picker--range__calendars-wrapper___8nWaW",
  "date-picker__navbar-buttons-wrapper": "lc-DatePicker-module__date-picker__navbar-buttons-wrapper___Hnksb"
}, ba = "date-picker", T_ = (t) => {
  const {
    onPreviousClick: a,
    onMonthChange: r,
    onNextClick: _,
    showNextButton: o,
    showPreviousButton: l,
    className: c,
    classNames: n,
    numberOfMonths: s,
    month: i,
    fromMonth: u,
    toMonth: m
  } = t, d = () => {
    typeof a == "function" && a();
  }, v = () => {
    typeof _ == "function" && _();
  }, f = () => {
    if (!u) {
      const h = Xe(i, 12);
      return r(h);
    }
    const b = Math.abs(la(i, u)), E = Xe(
      i,
      !Number.isNaN(b) && b > 12 ? 12 : b
    );
    return r(E);
  }, p = () => {
    if (!m) {
      const h = ca(i, 12);
      return r(h);
    }
    const b = Math.abs(la(m, i)), E = ca(
      i,
      !Number.isNaN(b) && b > 12 ? 12 : b
    );
    return s === 2 && qt(E, m) ? r(Xe(E, 1)) : r(E);
  };
  return /* @__PURE__ */ e.createElement("div", { className: c }, /* @__PURE__ */ e.createElement("div", { className: T[`${ba}__navbar-buttons-wrapper`] }, /* @__PURE__ */ e.createElement(
    "button",
    {
      "data-testid": "date-picker-prev-year-button",
      className: Le({
        [n.navButtonPrev]: !0,
        [n.navButtonInteractionDisabled]: !l
      }),
      onClick: f
    },
    /* @__PURE__ */ e.createElement(D, { source: dr, kind: "subtle" })
  ), /* @__PURE__ */ e.createElement(
    "button",
    {
      "data-testid": "date-picker-prev-month-button",
      className: Le({
        [n.navButtonPrev]: !0,
        [n.navButtonInteractionDisabled]: !l
      }),
      onClick: d
    },
    /* @__PURE__ */ e.createElement(D, { source: ur, kind: "subtle" })
  )), /* @__PURE__ */ e.createElement("div", { className: T[`${ba}__navbar-buttons-wrapper`] }, /* @__PURE__ */ e.createElement(
    "button",
    {
      "data-testid": "date-picker-next-month-button",
      className: Le({
        [n.navButtonNext]: !0,
        [n.navButtonInteractionDisabled]: !o
      }),
      onClick: v
    },
    /* @__PURE__ */ e.createElement(D, { source: mr, kind: "subtle" })
  ), /* @__PURE__ */ e.createElement(
    "button",
    {
      "data-testid": "date-picker-next-year-button",
      className: Le({
        [n.navButtonNext]: !0,
        [n.navButtonInteractionDisabled]: !o
      }),
      onClick: p
    },
    /* @__PURE__ */ e.createElement(D, { source: pr, kind: "subtle" })
  )));
}, I = "date-picker", Ua = (t, a) => {
  const { from: r, to: _ } = a;
  return !(_ && !Jt(t, _) && Kt(t, _) || r && !Jt(t, r) && !Kt(t, r));
}, Wa = (t, a, r) => a ? t && !qt(t, a) || r && qt(a, r) ? Xe(a, 1) : a : Xe(r || /* @__PURE__ */ new Date(), 1), D_ = (t, a) => {
  const r = {
    [T[`${I}__day--monday`]]: { daysOfWeek: [1] },
    [T[`${I}__day--sunday`]]: { daysOfWeek: [0] },
    [T[`${I}__day--start`]]: t,
    [T[`${I}__day--end`]]: t
  };
  if (!a || !t)
    return r;
  const _ = Oa(a, t);
  return _ > 0 ? A(w({}, r), {
    [T[`${I}__day--end`]]: a
  }) : _ < 0 ? A(w({}, r), {
    [T[`${I}__day--start`]]: a
  }) : r;
}, pt = (t, a) => {
  const r = a.find((_) => _.id === t);
  return r || void 0;
}, va = (t, a) => !!(!t || t && a), I_ = (t) => {
  const a = {};
  if (!t.initialSelectedItemKey)
    return a;
  const r = pt(
    t.initialSelectedItemKey,
    t.options
  );
  return r ? (a.selectedItem = t.initialSelectedItemKey, r.isManual && (t.initialFromDate && (a.from = t.initialFromDate), t.initialToDate && (a.to = t.initialToDate, a.temporaryTo = t.initialToDate)), a) : {};
}, A_ = (t, a) => w({
  container: Le({
    [T[`${I}`]]: !0,
    [T[`${I}--range`]]: t
  }),
  wrapper: T[`${I}__wrapper`],
  interactionDisabled: T[`${I}--interaction-disabled`],
  months: T[`${I}__months`],
  month: T[`${I}__month`],
  navBar: T[`${I}__nav-bar`],
  navButtonPrev: Le(
    T[`${I}__nav-button`],
    T[`${I}__nav-button--prev`]
  ),
  navButtonNext: Le(
    T[`${I}__nav-button`],
    T[`${I}__nav-button--next`]
  ),
  navButtonInteractionDisabled: T[`${I}__nav-button--interaction-disabled`],
  caption: T[`${I}__caption`],
  weekdays: T[`${I}__weekdays`],
  weekdaysRow: T[`${I}__weekdays-row`],
  weekday: T[`${I}__weekday`],
  body: T[`${I}__body`],
  week: T[`${I}__week`],
  weekNumber: T[`${I}__week-number`],
  day: T[`${I}__day`],
  footer: T[`${I}__footer`],
  todayButton: T[`${I}__today-button`],
  today: T[`${I}__day--today`],
  selected: T[`${I}__day--selected`],
  disabled: T[`${I}__day--disabled`],
  outside: T[`${I}__day--outside`],
  start: T[`${I}__day--start`],
  end: T[`${I}__day--end`]
}, a), ha = "date-picker", B_ = (t) => {
  const a = t.getDate();
  return /* @__PURE__ */ e.createElement("div", { className: T[`${ha}__day-wrapper`] }, /* @__PURE__ */ e.createElement("div", { className: T[`${ha}__day-content`] }, a));
}, P_ = (t) => {
  const E = t, {
    classNames: a,
    range: r,
    toMonth: _,
    month: o,
    fromMonth: l,
    firstDayOfWeek: c,
    numberOfMonths: n,
    navbarElement: s,
    renderDay: i,
    innerRef: u
  } = E, m = B(E, [
    "classNames",
    "range",
    "toMonth",
    "month",
    "fromMonth",
    "firstDayOfWeek",
    "numberOfMonths",
    "navbarElement",
    "renderDay",
    "innerRef"
  ]), [d, v] = e.useState(o || /* @__PURE__ */ new Date());
  e.useEffect(() => {
    o && o !== d && v(o);
  }, [o, d]), e.useEffect(() => {
    _ && (Ua(d, { from: l, to: _ }) || v(_));
  }, [d, t.toMonth, t.fromMonth]);
  const f = e.useCallback(
    (h) => {
      if (t.onMonthChange && h) {
        t.onMonthChange(h);
        return;
      }
      v(h);
    },
    [o, t.onMonthChange]
  );
  let p = 1;
  (c === 0 || c && c < 7) && (p = c);
  const b = e.useMemo(
    () => A_(r, a),
    [r, a]
  );
  return /* @__PURE__ */ e.createElement(
    gr,
    w({
      navbarElement: s || /* @__PURE__ */ e.createElement(
        T_,
        {
          month: d,
          classNames: b,
          numberOfMonths: n,
          onMonthChange: f,
          toMonth: _,
          fromMonth: l
        }
      ),
      ref: u,
      classNames: b,
      numberOfMonths: n,
      toMonth: _,
      fromMonth: l,
      firstDayOfWeek: p,
      month: d,
      renderDay: i || B_
    }, m)
  );
}, F_ = e.forwardRef(
  (t, a) => /* @__PURE__ */ e.createElement(P_, w({ innerRef: a }, t))
);
F_.displayName = "DatePicker";
var O = /* @__PURE__ */ ((t) => (t.NEW_SELECTED_ITEM = "NEW_SELECTED_ITEM", t.NEW_TEMPORARY_TO_VALUE = "NEW_TEMPORARY_TO_VALUE", t.CLEAR = "CLEAR", t.SET_FROM = "SET_FROM", t.SET_TO = "SET_TO", t.SELECT_FIRST_DAY = "SELECT_FIRST_DAY", t.SELECT_SECOND_DAY_AS_FROM = "SELECT_SECOND_DAY_AS_FROM", t.SELECT_SECOND_DAY_AS_TO = "SELECT_SECOND_DAY_AS_TO", t.CURRENT_MONTH_CHANGE = "CURRENT_MONTH_CHANGE", t))(O || {});
const R_ = (t) => {
  const a = Wa(
    t.initialFromDate,
    t.initialToDate,
    t.toMonth
  ), _ = w(w({}, {
    selectedItem: null,
    from: void 0,
    to: void 0,
    temporaryTo: void 0,
    currentMonth: a
  }), I_(t));
  function o(l, c) {
    switch (c.type) {
      case O.NEW_SELECTED_ITEM:
        return {
          selectedItem: c.payload.selectedItem,
          from: void 0,
          to: void 0,
          temporaryTo: void 0,
          currentMonth: a
        };
      case O.NEW_TEMPORARY_TO_VALUE:
        return A(w({}, l), {
          temporaryTo: c.payload.date
        });
      case O.SELECT_FIRST_DAY:
        return A(w({}, l), {
          from: c.payload.date,
          to: void 0,
          temporaryTo: void 0
        });
      case O.SELECT_SECOND_DAY_AS_FROM:
        return A(w({}, l), {
          from: c.payload.date,
          to: l.from,
          temporaryTo: l.from
        });
      case O.SELECT_SECOND_DAY_AS_TO:
        return A(w({}, l), {
          to: c.payload.date,
          temporaryTo: c.payload.date
        });
      case O.CURRENT_MONTH_CHANGE:
        return A(w({}, l), {
          currentMonth: c.payload.date
        });
      case O.SET_FROM:
        return A(w({}, l), {
          from: c.payload.date
        });
      case O.SET_TO:
        return A(w({}, l), {
          to: c.payload.date
        });
      default:
        return l;
    }
  }
  return er(o, _);
}, M_ = ({
  options: t,
  initialSelectedItemKey: a,
  initialFromDate: r,
  initialToDate: _,
  toMonth: o,
  onChange: l,
  children: c
}) => {
  const n = tr(
    a || null
  ), [s, i] = R_({
    options: t,
    initialSelectedItemKey: a,
    initialFromDate: r,
    initialToDate: _,
    toMonth: o,
    onChange: l,
    children: c
  });
  tt(() => {
    i({
      type: O.SET_FROM,
      payload: { date: r }
    });
  }, [r]), tt(() => {
    i({
      type: O.SET_TO,
      payload: { date: _ }
    });
  }, [_]), tt(() => {
    const p = Wa(
      r,
      _,
      o
    );
    i({
      type: O.CURRENT_MONTH_CHANGE,
      payload: { date: p }
    });
  }, [o, r, _]), tt(() => {
    const { from: p, selectedItem: b, to: E } = s;
    if (!(p && E))
      return;
    const h = pt(b, t);
    h && (l == null || l(A(w({}, h), {
      value: {
        from: p,
        to: E
      }
    })));
  }, [s.from, s.to, s.selectedItem, t, l]), tt(() => {
    const { selectedItem: p } = s;
    if (p === n.current)
      return;
    if (!p) {
      l(null);
      return;
    }
    if (!pt(p, t))
      return;
    const E = t.reduce(
      (h, N) => A(w({}, h), { [N.id]: N }),
      {}
    );
    l(E[p]);
  }, [l, s.selectedItem, t]);
  const u = ht(
    (p) => {
      const b = o ? Oa(o, p) >= 0 : !0;
      !va(s.from, s.to) && b && i({
        type: O.NEW_TEMPORARY_TO_VALUE,
        payload: { date: p }
      });
    },
    [o, s.from, s.to]
  ), m = ht(
    (p) => {
      const { from: b, to: E } = s;
      Ua(p, { to: o }) && (va(b, E) ? i({
        type: O.SELECT_FIRST_DAY,
        payload: { date: p }
      }) : b && Jt(p, b) || b && Kt(p, b) ? i({
        type: O.SELECT_SECOND_DAY_AS_TO,
        payload: { date: p }
      }) : i({
        type: O.SELECT_SECOND_DAY_AS_FROM,
        payload: { date: p }
      }));
    },
    [o, s.from, s.to]
  ), d = ht(
    (p) => {
      if (p === null) {
        i({
          type: O.NEW_SELECTED_ITEM,
          payload: { selectedItem: null }
        });
        return;
      }
      pt(p, t) && i({
        type: O.NEW_SELECTED_ITEM,
        payload: { selectedItem: p }
      });
    },
    [t]
  ), v = ht((p) => {
    i({
      type: O.CURRENT_MONTH_CHANGE,
      payload: { date: p }
    });
  }, []);
  return c((() => {
    const { currentMonth: p, from: b, selectedItem: E, temporaryTo: h, to: N } = s, $ = kt(
      () => D_(b, h),
      [b, h]
    ), y = kt(() => pt(E, t), [t, E]), k = kt(() => [b, { from: b, to: h }], [b, h]), C = kt(() => o ? { after: o } : void 0, [o]);
    return {
      select: {
        onItemSelect: d,
        selected: E || ""
      },
      inputs: {
        fromDate: b,
        toDate: N
      },
      datepicker: {
        range: !0,
        month: p,
        numberOfMonths: 2,
        onDayClick: m,
        selectedDays: k,
        modifiers: $,
        initialMonth: o && Xe(o, 1),
        toMonth: o,
        disabledDays: C,
        onDayMouseEnter: u,
        onMonthChange: v
      },
      selectedOption: y
    };
  })());
};
M_.defaultProps = {
  options: [
    {
      id: "default",
      label: "Default option",
      isManual: !0,
      value: {
        from: void 0,
        to: void 0
      }
    }
  ]
};
const ka = {
  "field-error": "lc-FieldError-module__field-error___IDkPT",
  "field-error__icon": "lc-FieldError-module__field-error__icon___D4UNC"
}, ya = "field-error", ra = (_) => {
  var o = _, {
    children: t,
    className: a = ""
  } = o, r = B(o, [
    "children",
    "className"
  ]);
  const l = g(ka[ya], a);
  return /* @__PURE__ */ e.createElement(P, A(w({ as: "span", size: "sm" }, r), { className: l }), /* @__PURE__ */ e.createElement(
    D,
    {
      source: Zt,
      className: ka[`${ya}__icon`],
      size: "small"
    }
  ), t);
}, Gt = {
  "field-group": "lc-FieldGroup-module__field-group___gy8lp",
  "field-group--inline": "lc-FieldGroup-module__field-group--inline___or4qf",
  "field-group--stretched": "lc-FieldGroup-module__field-group--stretched___6rkuO"
}, Ut = "field-group", Cl = (n) => {
  var s = n, {
    className: t = "",
    children: a,
    description: r,
    error: _,
    inline: o,
    stretch: l
  } = s, c = B(s, [
    "className",
    "children",
    "description",
    "error",
    "inline",
    "stretch"
  ]);
  const i = g(Gt[Ut], t, {
    [Gt[`${Ut}--inline`]]: o,
    [Gt[`${Ut}--stretched`]]: l
  });
  return /* @__PURE__ */ e.createElement("div", A(w({}, c), { className: i }), a, _ && /* @__PURE__ */ e.createElement(ra, null, _), r && /* @__PURE__ */ e.createElement(Rt, null, r));
}, L_ = "lc-Form-module__form___1nOYF", O_ = "lc-Form-module__form__header___U4B7t", H_ = "lc-Form-module__form__footer___1-sUX", z_ = "lc-Form-module__form__label___spg-J", G_ = "lc-Form-module__form__helper___bhfwS", st = {
  form: L_,
  form__header: O_,
  form__footer: H_,
  form__label: z_,
  form__helper: G_
}, it = "form", Nl = (c) => {
  var n = c, {
    className: t,
    children: a,
    labelText: r,
    helperText: _,
    formFooter: o
  } = n, l = B(n, [
    "className",
    "children",
    "labelText",
    "helperText",
    "formFooter"
  ]);
  return /* @__PURE__ */ e.createElement("form", w({ className: g(st[it], t) }, l), (r || _) && /* @__PURE__ */ e.createElement("div", { className: st[`${it}__header`] }, r && /* @__PURE__ */ e.createElement(et, { size: "sm", className: st[`${it}__label`] }, r), _ && /* @__PURE__ */ e.createElement(P, { as: "p", size: "sm", className: st[`${it}__helper`] }, _)), a, o && /* @__PURE__ */ e.createElement("div", { className: st[`${it}__footer`] }, o));
}, z = {
  "form-field": "lc-FormField-module__form-field___JqnX6",
  "form-field--inline": "lc-FormField-module__form-field--inline___4DgIq",
  "form-field__wrapper--inline": "lc-FormField-module__form-field__wrapper--inline___Ubvrn",
  "form-field__label": "lc-FormField-module__form-field__label___hNCFS",
  "form-field__label--inline": "lc-FormField-module__form-field__label--inline___QxyM6",
  "form-field__label--no-text": "lc-FormField-module__form-field__label--no-text___Ohs-I",
  "form-field__label-wrapper": "lc-FormField-module__form-field__label-wrapper___61-NO",
  "form-field__label-wrapper--inline": "lc-FormField-module__form-field__label-wrapper--inline___YVYza",
  "form-field__label-adornment": "lc-FormField-module__form-field__label-adornment___HB0z7",
  "form-field__label-adornment--inline": "lc-FormField-module__form-field__label-adornment--inline___pwRXm",
  "form-field__label-left-node": "lc-FormField-module__form-field__label-left-node___dvipB",
  "form-field__label-right-node": "lc-FormField-module__form-field__label-right-node___LR-03",
  "form-field__label-right-node--inline": "lc-FormField-module__form-field__label-right-node--inline___BkiPG",
  "form-field__row-break": "lc-FormField-module__form-field__row-break___DCBjC",
  "form-field__content": "lc-FormField-module__form-field__content___XaOx3",
  "form-field__content__description": "lc-FormField-module__form-field__content__description___NpVw4"
}, G = "form-field", xl = ({
  inline: t,
  error: a,
  description: r,
  labelText: _,
  labelAdornment: o,
  className: l,
  labelFor: c,
  children: n,
  labelRightNode: s
}) => {
  const i = e.useRef(null), [u, m] = e.useState("auto"), d = g(
    z[G],
    {
      [z[`${G}--inline`]]: t
    },
    l
  );
  return e.useEffect(() => {
    const v = i;
    return t && v.current ? m(`${v.current.clientHeight}px`) : m("auto");
  }), /* @__PURE__ */ e.createElement("div", { className: d }, s && t && /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g(
        z[`${G}__label-right-node`],
        z[`${G}__label-right-node--inline`]
      )
    },
    s
  ), /* @__PURE__ */ e.createElement("div", { className: z[`${G}__row-break`] })), /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g(
        z[`${G}__wrapper`],
        t && z[`${G}__wrapper--inline`]
      )
    },
    (_ || s) && /* @__PURE__ */ e.createElement(
      "div",
      {
        className: g(
          z[`${G}__label`],
          t && z[`${G}__label--inline`],
          !_ && z[`${G}__label--no-text`]
        )
      },
      _ && /* @__PURE__ */ e.createElement(
        "div",
        {
          className: g(
            z[`${G}__label-wrapper`],
            t && z[`${G}__label-wrapper--inline`]
          ),
          style: {
            height: u
          }
        },
        /* @__PURE__ */ e.createElement(
          "label",
          {
            className: z[`${G}__label-left-node`],
            htmlFor: c
          },
          /* @__PURE__ */ e.createElement(P, { as: "span", size: "sm" }, _)
        ),
        o && /* @__PURE__ */ e.createElement(
          "div",
          {
            className: g(
              z[`${G}__label-adornment`],
              t && z[`${G}__label-adornment--inline`]
            )
          },
          o
        )
      ),
      s && !t && /* @__PURE__ */ e.createElement("div", { className: g(z[`${G}__label-right-node`]) }, s)
    ),
    /* @__PURE__ */ e.createElement("div", { className: g(z[`${G}__content`]) }, /* @__PURE__ */ e.createElement("div", { ref: i }, n), a && /* @__PURE__ */ e.createElement(ra, null, a), !a && r && /* @__PURE__ */ e.createElement(
      Rt,
      {
        className: g(z[`${G}__content__description`])
      },
      r
    ))
  ));
}, Nt = {
  "form-group": "lc-FormGroup-module__form-group___e2JHa",
  "form-group__header": "lc-FormGroup-module__form-group__header___DgGmR",
  "form-group__label": "lc-FormGroup-module__form-group__label___NC-JW",
  "form-group__helper": "lc-FormGroup-module__form-group__helper___SRuxe"
}, xt = "form-group", Sl = (l) => {
  var c = l, {
    className: t = "",
    children: a,
    labelText: r,
    helperText: _
  } = c, o = B(c, [
    "className",
    "children",
    "labelText",
    "helperText"
  ]);
  const n = g(Nt[xt], t);
  return /* @__PURE__ */ e.createElement(
    "div",
    A(w(A(w({}, o), {
      role: "group"
    }), r && { "aria-label": r }), {
      className: n
    }),
    /* @__PURE__ */ e.createElement("div", { className: Nt[`${xt}__header`] }, /* @__PURE__ */ e.createElement(et, { as: "div", size: "sm", className: Nt[`${xt}__label`] }, r), _ && /* @__PURE__ */ e.createElement(P, { as: "div", size: "sm", className: Nt[`${xt}__helper`] }, _)),
    a
  );
}, U_ = "lc-Input-module__input___qeMAQ", he = {
  input: U_,
  "input--focused": "lc-Input-module__input--focused___wQH5-",
  "input--disabled": "lc-Input-module__input--disabled___HidXX",
  "input--crop": "lc-Input-module__input--crop___uzJS8",
  "input--error": "lc-Input-module__input--error___DlDbC",
  "input--xsmall": "lc-Input-module__input--xsmall___9u99K",
  "input--compact": "lc-Input-module__input--compact___iKSQZ",
  "input--medium": "lc-Input-module__input--medium___3BjqQ",
  "input--large": "lc-Input-module__input--large___jX5RW",
  "input__icon--disabled": "lc-Input-module__input__icon--disabled___XO3dc",
  "input__icon--left": "lc-Input-module__input__icon--left___BcySs",
  "input__icon--right": "lc-Input-module__input__icon--right___VefSK",
  "input__visibility-button": "lc-Input-module__input__visibility-button___eWrVe"
}, ke = "input", Ea = (t, a) => e.cloneElement(t.source, {
  "data-testid": `input-icon-${t.place}`,
  className: g(
    he[`${ke}__icon`],
    he[`${ke}__icon--${t.place}`],
    {
      [he[`${ke}__icon--disabled`]]: a
    }
  )
}), Tl = e.forwardRef(
  (s, n) => {
    var i = s, {
      inputSize: t = "medium",
      error: a = !1,
      disabled: r,
      icon: _ = null,
      className: o,
      cropOnBlur: l = !0
    } = i, c = B(i, [
      "inputSize",
      "error",
      "disabled",
      "icon",
      "className",
      "cropOnBlur"
    ]);
    const [u, m] = e.useState(!1), [d, v] = e.useState(!1), { type: f, onFocus: p, onBlur: b } = c, E = g(
      o,
      he[ke],
      he[`${ke}--${t}`],
      {
        [he[`${ke}--disabled`]]: r,
        [he[`${ke}--focused`]]: u,
        [he[`${ke}--error`]]: a,
        [he[`${ke}--crop`]]: l
      }
    ), h = r ? "var(--content-disabled)" : "var(--content-default)", N = d ? rr : _r, $ = _ && _.place === "left", y = _ && f !== "password" && _.place === "right";
    return /* @__PURE__ */ e.createElement("div", { className: E, "aria-disabled": r, "tab-index": "0" }, $ && Ea(_, r), /* @__PURE__ */ e.createElement(
      "input",
      A(w({}, c), {
        ref: n,
        onFocus: (k) => {
          m(!0), p == null || p(k);
        },
        onBlur: (k) => {
          m(!1), b == null || b(k);
        },
        disabled: r,
        type: f && !d ? f : "text"
      })
    ), y && Ea(_, r), f === "password" && /* @__PURE__ */ e.createElement(
      M,
      {
        disabled: r,
        kind: "text",
        size: "compact",
        icon: /* @__PURE__ */ e.createElement(D, { customColor: h, source: N }),
        onClick: () => v((k) => !k),
        className: he[`${ke}__visibility-button`]
      }
    ));
  }
), W_ = "lc-Link-module__link___kqx52", $a = {
  link: W_,
  "link--bold": "lc-Link-module__link--bold___1rGdO"
}, wa = "link", Dl = (_) => {
  var o = _, {
    bold: t = !1,
    className: a = ""
  } = o, r = B(o, [
    "bold",
    "className"
  ]);
  return /* @__PURE__ */ e.createElement(
    "a",
    w({
      className: g(
        $a[wa],
        t && $a[`${wa}--bold`],
        a
      )
    }, r)
  );
}, V_ = "lc-Modal-module__modal__body___M-jmN", Y_ = "lc-Modal-module__modal__header___Fp5VE", q_ = "lc-Modal-module__modal__heading___G9KVK", J_ = "lc-Modal-module__modal__footer___2LYdc", ae = {
  "modal-base": "lc-Modal-module__modal-base___hbL1U",
  "modal-base--full-space": "lc-Modal-module__modal-base--full-space___KcGFL",
  "modal-base__overlay": "lc-Modal-module__modal-base__overlay___kCQ8t",
  "modal-base__overlay--visible": "lc-Modal-module__modal-base__overlay--visible___zUNJL",
  "modal-base__close": "lc-Modal-module__modal-base__close___gJlgr",
  "modal-base__close--label-type": "lc-Modal-module__modal-base__close--label-type___Om0Vb",
  modal__body: V_,
  modal__header: Y_,
  "modal__label-header": "lc-Modal-module__modal__label-header___ZPtEy",
  modal__heading: q_,
  "modal__label-heading": "lc-Modal-module__modal__label-heading___WnYcF",
  modal__footer: J_
}, St = "modal-base", K_ = (n) => {
  var s = n, {
    children: t,
    className: a = "",
    onClose: r,
    closeOnEscPress: _ = !0,
    closeOnOverlayPress: o = !0,
    fullSpaceContent: l
  } = s, c = B(s, [
    "children",
    "className",
    "onClose",
    "closeOnEscPress",
    "closeOnOverlayPress",
    "fullSpaceContent"
  ]);
  const i = g(
    ae[St],
    a,
    l && ae[`${St}--full-space`]
  );
  e.useEffect(() => {
    if (!_)
      return;
    const m = (d) => {
      d.key === L.esc && r();
    };
    return document.addEventListener("keyup", m, !0), () => document.removeEventListener("keyup", m, !0);
  }, [_]);
  const u = (m) => {
    o && m.target === m.currentTarget && r();
  };
  return /* @__PURE__ */ e.createElement(
    "div",
    {
      "data-testid": "lc-modal-overlay",
      onMouseDown: u,
      className: g(
        ae[`${St}__overlay`],
        ae[`${St}__overlay--visible`]
      )
    },
    /* @__PURE__ */ e.createElement(
      "div",
      w({
        role: "dialog",
        "aria-modal": !0,
        className: i
      }, c),
      t
    )
  );
}, Ca = ({
  labelType: t,
  customColor: a,
  onClick: r
}) => /* @__PURE__ */ e.createElement(
  M,
  {
    kind: "plain",
    title: "Close modal",
    className: g(
      ae["modal-base__close"],
      t && ae["modal-base__close--label-type"]
    ),
    onClick: r,
    icon: /* @__PURE__ */ e.createElement(D, { source: Te, size: "medium", customColor: a })
  }
), Re = "modal", Il = (i) => {
  var u = i, {
    children: t,
    className: a = "",
    heading: r,
    labelHeading: _,
    fullSpaceContent: o,
    footer: l,
    onClose: c,
    contentClassName: n
  } = u, s = B(u, [
    "children",
    "className",
    "heading",
    "labelHeading",
    "fullSpaceContent",
    "footer",
    "onClose",
    "contentClassName"
  ]);
  const m = g(ae[Re], a), d = typeof t == "string", v = (f) => {
    f.preventDefault(), f.stopPropagation(), c();
  };
  return /* @__PURE__ */ e.createElement(
    K_,
    w({
      className: m,
      fullSpaceContent: o,
      onClose: c
    }, s),
    _ && /* @__PURE__ */ e.createElement("div", { className: ae[`${Re}__label-header`] }, /* @__PURE__ */ e.createElement(
      et,
      {
        size: "md",
        as: "div",
        className: ae[`${Re}__label-heading`]
      },
      _
    ), /* @__PURE__ */ e.createElement(
      Ca,
      {
        labelType: !!_,
        customColor: "var(--color-white)",
        onClick: v
      }
    )),
    !_ && r && /* @__PURE__ */ e.createElement("div", { className: ae[`${Re}__header`] }, /* @__PURE__ */ e.createElement(
      et,
      {
        size: "md",
        as: "div",
        className: ae[`${Re}__heading`]
      },
      r
    ), /* @__PURE__ */ e.createElement(Ca, { onClick: v })),
    /* @__PURE__ */ e.createElement(
      "div",
      {
        "data-testid": "modal-body",
        className: g(ae[`${Re}__body`], n)
      },
      d ? /* @__PURE__ */ e.createElement(P, { as: "div" }, t) : t
    ),
    l && /* @__PURE__ */ e.createElement("div", { className: ae[`${Re}__footer`] }, l)
  );
}, Al = ({
  children: t,
  className: a = "",
  parentElementName: r = "body",
  zIndex: _
}) => {
  const [o] = e.useState(() => document.createElement("div"));
  return a && o.classList.add(a), _ && (o.style.zIndex = _.toString()), e.useEffect(() => {
    var l;
    return (l = document.querySelector(r)) == null || l.appendChild(o), () => {
      var c;
      (c = document.querySelector(r)) == null || c.removeChild(o);
    };
  }, [r]), /* @__PURE__ */ e.createElement(e.Fragment, null, fr.createPortal(t, o));
}, Ve = {
  "modal-header": "lc-ModalHeader-module__modal-header___xk3bd",
  "modal-header__heading-left-node": "lc-ModalHeader-module__modal-header__heading-left-node___1--G5",
  "modal-header__heading-body": "lc-ModalHeader-module__modal-header__heading-body___HHEF-",
  "modal-header__heading-title": "lc-ModalHeader-module__modal-header__heading-title___2R9pm",
  "modal-header__heading-description": "lc-ModalHeader-module__modal-header__heading-description___54Xvf"
}, Ye = "modal-header", Bl = ({
  title: t,
  iconProps: a,
  avatarProps: r,
  children: _,
  className: o = ""
}) => {
  const l = ir(Ve[Ye], o);
  return /* @__PURE__ */ e.createElement("div", { className: l }, a && /* @__PURE__ */ e.createElement(
    D,
    w({
      className: Ve[`${Ye}__heading-left-node`]
    }, a)
  ), r && /* @__PURE__ */ e.createElement(
    t_,
    w({
      className: Ve[`${Ye}__heading-left-node`]
    }, r)
  ), /* @__PURE__ */ e.createElement("div", { className: Ve[`${Ye}__heading-body`] }, /* @__PURE__ */ e.createElement(
    et,
    {
      as: "div",
      size: "md",
      className: Ve[`${Ye}__heading-title`]
    },
    t
  ), _ && /* @__PURE__ */ e.createElement(
    P,
    {
      as: "div",
      className: Ve[`${Ye}__heading-description`]
    },
    _
  )));
}, qe = {
  "numeric-input": "lc-NumericInput-module__numeric-input___j1esc",
  "numeric-input__increment": "lc-NumericInput-module__numeric-input__increment___i3Tys",
  "numeric-input__decrement": "lc-NumericInput-module__numeric-input__decrement___3qgn0",
  "numeric-input--disabled": "lc-NumericInput-module__numeric-input--disabled___yaRvQ",
  "numeric-input--no-controls": "lc-NumericInput-module__numeric-input--no-controls___D90rG",
  "numeric-input--error": "lc-NumericInput-module__numeric-input--error___TMxRx"
}, Je = "numeric-input", Pl = (u) => {
  var m = u, {
    className: t,
    error: a,
    value: r,
    max: _,
    min: o,
    disabled: l,
    noControls: c,
    style: n,
    onChange: s
  } = m, i = B(m, [
    "className",
    "error",
    "value",
    "max",
    "min",
    "disabled",
    "noControls",
    "style",
    "onChange"
  ]);
  const d = e.useRef(null), v = g(
    qe[Je],
    {
      [qe[`${Je}--error`]]: a,
      [qe[`${Je}--no-controls`]]: c,
      [qe[`${Je}--disabled`]]: l
    },
    t
  ), f = (k) => s(String(k)), p = (k) => _ !== void 0 && k > _ ? _ : o !== void 0 && k < o ? o : k, b = (k) => {
    const C = parseInt(r, 10) + k;
    return f(p(C));
  }, E = (k) => {
    k.key === L.arrowDown && (k.preventDefault(), b(-1)), k.key === L.arrowUp && (k.preventDefault(), b(1));
  }, h = (k, C) => C !== void 0 && parseInt(k, 10) === C, N = (k) => {
    k.preventDefault(), k.stopPropagation();
    const C = k.currentTarget.value.replace(
      /((?!([-]|([-]?\d+))).)/,
      ""
    );
    if (C === "" || C === "-")
      return f(C);
    const x = parseInt(C, 10);
    return f(p(x));
  }, $ = () => {
    var k;
    return (k = d.current) == null || k.focus(), b(1);
  }, y = () => {
    var k;
    return (k = d.current) == null || k.focus(), b(-1);
  };
  return /* @__PURE__ */ e.createElement("div", { className: v, style: n }, /* @__PURE__ */ e.createElement(
    "input",
    A(w({
      type: "text",
      ref: d
    }, i), {
      value: r,
      disabled: l,
      onChange: N,
      onKeyDown: E,
      min: o,
      max: _
    })
  ), !c && /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement(
    "button",
    {
      tabIndex: -1,
      disabled: l || h(r, _),
      onClick: $,
      "aria-label": "Increment value",
      className: qe[`${Je}__increment`],
      type: "button"
    },
    /* @__PURE__ */ e.createElement(
      D,
      {
        source: Ft,
        disabled: l || h(r, _),
        kind: "primary"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "button",
    {
      tabIndex: -1,
      disabled: l || h(r, o),
      "aria-label": "Decrement value",
      className: qe[`${Je}__decrement`],
      onClick: y,
      type: "button"
    },
    /* @__PURE__ */ e.createElement(
      D,
      {
        source: bt,
        kind: "primary",
        disabled: l || h(r, o)
      }
    )
  )));
}, Qe = "select-all";
function j_(t, a) {
  const r = {};
  if (a && a.length > 1) {
    const _ = t.map((o) => o.key).filter((o) => a.includes(o));
    for (let o = 0; o < _.length; o++) {
      const l = _[o], c = _[o + 1], n = _[o - 1], s = c && Math.abs(
        t.findIndex((u) => u.key === c) - t.findIndex((u) => u.key === l)
      ) === 1, i = n && Math.abs(
        t.findIndex((u) => u.key === n) - t.findIndex((u) => u.key === l)
      ) === 1;
      s && i ? r[l] = "middle" : s ? r[l] = "top" : i && (r[l] = "bottom");
    }
  }
  return r;
}
const W = {
  "list-wrapper": "lc-PickerList-module__list-wrapper___ZJjPY",
  "picker-list": "lc-PickerList-module__picker-list___FqdgZ",
  "picker-list__no-results": "lc-PickerList-module__picker-list__no-results___tX172",
  "picker-list__item": "lc-PickerList-module__picker-list__item___8QNS0",
  "picker-list__item--select-all": "lc-PickerList-module__picker-list__item--select-all___PlTHV",
  "picker-list__item__header": "lc-PickerList-module__picker-list__item__header___mxmg0",
  "picker-list__item__content": "lc-PickerList-module__picker-list__item__content___D-tsE",
  "picker-list__item__custom": "lc-PickerList-module__picker-list__item__custom___kJEP6",
  "picker-list__item__checkbox": "lc-PickerList-module__picker-list__item__checkbox___WFuqO",
  "picker-list__item__avatar": "lc-PickerList-module__picker-list__item__avatar___hZjIP",
  "picker-list__item__icon": "lc-PickerList-module__picker-list__item__icon___BYspu",
  "picker-list__item__label-container": "lc-PickerList-module__picker-list__item__label-container___yZXW5",
  "picker-list__item__main-label": "lc-PickerList-module__picker-list__item__main-label___uRgQw",
  "picker-list__item__secondary-label": "lc-PickerList-module__picker-list__item__secondary-label___lpkFW"
}, ie = "picker-list__item", Q_ = ({
  item: t,
  isItemSelected: a,
  currentItemKey: r,
  isAdjacentStyleApplied: _,
  onSelect: o
}) => {
  const l = (n) => n != null && n.customElement ? /* @__PURE__ */ e.createElement("div", { className: W[`${ie}__custom`] }, n.customElement.listItemBody) : /* @__PURE__ */ e.createElement(e.Fragment, null, n.showCheckbox && /* @__PURE__ */ e.createElement(
    "input",
    {
      type: "checkbox",
      className: W[`${ie}__checkbox`],
      checked: a
    }
  ), n.icon && /* @__PURE__ */ e.createElement(
    D,
    {
      className: W[`${ie}__icon`],
      kind: "link",
      source: n.icon
    }
  ), n.avatarSrc && /* @__PURE__ */ e.createElement(
    "img",
    {
      className: g(W[`${ie}__avatar`]),
      src: n.avatarSrc,
      alt: n.name
    }
  ), /* @__PURE__ */ e.createElement("div", { className: W[`${ie}__label-container`] }, /* @__PURE__ */ e.createElement(
    "span",
    {
      className: g({
        [W[`${ie}__main-label`]]: n.secondaryText
      })
    },
    n.name
  ), n.secondaryText && /* @__PURE__ */ e.createElement("span", { className: W[`${ie}__secondary-label`] }, n.secondaryText))), c = (n) => {
    !n.disabled && o(n);
  };
  return t.groupHeader ? /* @__PURE__ */ e.createElement(
    "li",
    {
      role: "option",
      key: t.key,
      className: W[`${ie}__header`]
    },
    t.name
  ) : /* @__PURE__ */ e.createElement(
    "li",
    {
      "data-testid": t.key,
      ref: (n) => {
        r === t.key && (n == null || n.scrollIntoView({ block: "nearest" }));
      },
      role: "option",
      "aria-current": r === t.key,
      "aria-selected": a,
      "aria-disabled": t.disabled,
      id: t.key,
      key: t.key,
      "data-adjacent": _,
      className: g(W[ie], {
        [W[`${ie}__custom`]]: t == null ? void 0 : t.customElement
      }),
      onClick: () => c(t)
    },
    /* @__PURE__ */ e.createElement("div", { className: W[`${ie}__content`] }, l(t)),
    a && !t.showCheckbox && /* @__PURE__ */ e.createElement(
      D,
      {
        kind: "link",
        source: Xt,
        customColor: "var(--content-basic-info)"
      }
    )
  );
}, jt = "picker-list", Na = `${jt}__item`, Z_ = ({
  isOpen: t,
  items: a,
  selectedItemsKeys: r,
  emptyStateText: _ = "No results found",
  selectAllOptionText: o,
  isMultiSelect: l,
  onClose: c,
  onSelect: n,
  onSelectAll: s
}) => {
  const i = g(W[jt], {
    [W[`${jt}__no-results`]]: a.length === 0
  }), [u, m] = e.useState(
    null
  ), d = e.useRef(-1), v = e.useRef(0), f = e.useRef(null), [p, b] = e.useState({}), E = (x) => {
    if (x.key === L.esc && (x.preventDefault(), c()), x.key === L.arrowUp && d.current > 0 && (x.preventDefault(), d.current = N(), m(a[d.current].key)), x.key === L.arrowDown && d.current + 1 < a.length && (x.preventDefault(), d.current = $(), m(a[d.current].key)), x.key === L.enter && a[d.current] && !a[d.current].disabled) {
      if (x.preventDefault(), a[d.current].key === Qe)
        return s();
      n(a[d.current]);
    }
  };
  e.useEffect(() => {
    if (d.current > -1 && a.length > 0 && a[d.current] && m(a[d.current].key), t)
      return document.addEventListener("keydown", E), () => document.removeEventListener("keydown", E);
    d.current = -1, v.current = 0, m(null);
  }, [a, t, E]), e.useEffect(() => {
    b(j_(a, r));
  }, [r, a]);
  const h = (x) => !!a[x] && (a[x].disabled || a[x].groupHeader), N = () => {
    for (d.current = d.current - 1; h(d.current) && (d.current = d.current - 1, !!h(d.current)); )
      ;
    return v.current = d.current, d.current;
  }, $ = () => {
    if (d.current = d.current + 1, !h(d.current))
      return v.current = d.current, d.current;
    for (; h(d.current); ) {
      if (d.current = d.current + 1, d.current === a.length && !h(d.current))
        return v.current;
      if (!h(d.current))
        break;
    }
    return v.current = d.current, d.current;
  }, y = () => s(), k = (x) => r ? r.includes(x) : !1, C = () => !l || l && !o ? null : /* @__PURE__ */ e.createElement(
    "li",
    {
      ref: (x) => {
        u === Qe && (x == null || x.scrollIntoView({ block: "nearest" }));
      },
      role: "option",
      "aria-current": u === Qe,
      id: Qe,
      key: Qe,
      className: g(
        W[Na],
        W[`${Na}--select-all`]
      ),
      onClick: y
    },
    o
  );
  return t ? a.length === 0 ? /* @__PURE__ */ e.createElement("div", { className: W["list-wrapper"] }, /* @__PURE__ */ e.createElement("div", { className: i }, _)) : /* @__PURE__ */ e.createElement("div", { className: W["list-wrapper"] }, /* @__PURE__ */ e.createElement(
    "ul",
    {
      ref: f,
      className: i,
      role: "listbox",
      tabIndex: -1
    },
    C(),
    a.map((x) => /* @__PURE__ */ e.createElement(
      Q_,
      {
        item: x,
        isItemSelected: k(x.key),
        isAdjacentStyleApplied: p[x.key],
        currentItemKey: u,
        onSelect: n
      }
    ))
  )) : null;
}, Q = {
  "picker-trigger": "lc-Trigger-module__picker-trigger___BIvTx",
  "picker-trigger--multi-select": "lc-Trigger-module__picker-trigger--multi-select___rLpTy",
  "picker-trigger--multi-select--with-items": "lc-Trigger-module__picker-trigger--multi-select--with-items___QFhY2",
  "picker-trigger__controls": "lc-Trigger-module__picker-trigger__controls___EsSeM",
  "picker-trigger__controls--compact": "lc-Trigger-module__picker-trigger__controls--compact___g3P6e",
  "picker-trigger__controls--medium": "lc-Trigger-module__picker-trigger__controls--medium___G4OYQ",
  "picker-trigger__controls--large": "lc-Trigger-module__picker-trigger__controls--large___h-egq",
  "picker-trigger__clear-icon": "lc-Trigger-module__picker-trigger__clear-icon___8H-4T",
  "picker-trigger__content": "lc-Trigger-module__picker-trigger__content___bYnCH",
  "picker-trigger__content--compact": "lc-Trigger-module__picker-trigger__content--compact___hoBbY",
  "picker-trigger__content--medium": "lc-Trigger-module__picker-trigger__content--medium___Grjol",
  "picker-trigger__content--large": "lc-Trigger-module__picker-trigger__content--large___hJB9C",
  "picker-trigger__content--with-items": "lc-Trigger-module__picker-trigger__content--with-items___IKMsm",
  "picker-trigger--disabled": "lc-Trigger-module__picker-trigger--disabled___7H4tD",
  "picker-trigger--focused": "lc-Trigger-module__picker-trigger--focused___vTr39",
  "picker-trigger--error": "lc-Trigger-module__picker-trigger--error___Rh8Qd"
}, K = "picker-trigger", X_ = ({
  children: t,
  isSearchDisabled: a,
  isDisabled: r,
  isError: _,
  isItemSelected: o,
  isOpen: l,
  isRequired: c,
  isMultiSelect: n,
  size: s = "medium",
  hideClearButton: i,
  onTrigger: u,
  onClear: m,
  testId: d
}) => {
  const v = e.useRef(null), f = g(
    Q[K],
    Q[`${K}--${s}`],
    n && Q[`${K}--multi-select`],
    n && o && Q[`${K}--multi-select--with-items`],
    r && Q[`${K}--disabled`],
    l && Q[`${K}--focused`],
    _ && Q[`${K}--error`]
  );
  e.useEffect(() => {
    const h = (N) => {
      document.activeElement === v.current && N.key !== L.tab && u(N);
    };
    return a || document.addEventListener("keydown", h), () => {
      a || document.removeEventListener("keydown", h);
    };
  }, [a]);
  const p = (h) => {
    u(h);
  }, b = (h) => {
    h.stopPropagation(), m();
  }, E = !i && o && !r && !c;
  return /* @__PURE__ */ e.createElement(
    "div",
    {
      ref: v,
      className: f,
      onClick: p,
      tabIndex: 0,
      "data-testid": d
    },
    /* @__PURE__ */ e.createElement(
      "div",
      {
        className: g(
          Q[`${K}__content`],
          Q[`${K}__content--${s}`],
          n && o && Q[`${K}__content--with-items`]
        )
      },
      t
    ),
    /* @__PURE__ */ e.createElement(
      "div",
      {
        className: g(
          Q[`${K}__controls`],
          Q[`${K}__controls--${s}`]
        )
      },
      E && /* @__PURE__ */ e.createElement(
        "div",
        {
          "data-testid": `${K}__clear-icon`,
          className: Q[`${K}__clear-icon`],
          onClick: b
        },
        /* @__PURE__ */ e.createElement(D, { kind: "primary", size: "small", source: Te })
      ),
      /* @__PURE__ */ e.createElement(
        D,
        {
          className: Q[`${K}__chevron-icon`],
          source: l ? Ft : bt,
          size: "medium",
          disabled: r
        }
      )
    )
  );
}, eo = "lc-Tag-module__tag___1QRVY", to = "lc-Tag-module__tag__remove___UmtrW", ao = "lc-Tag-module__icon___2Z-35", ro = "lc-Tag-module__tag__node___rZTzB", _o = "lc-Tag-module__tag__content___q6vKK", Ee = {
  tag: eo,
  "tag--success": "lc-Tag-module__tag--success___P2hXQ",
  tag__remove: to,
  "tag--error": "lc-Tag-module__tag--error___1cjsm",
  "tag--warning": "lc-Tag-module__tag--warning___PstMG",
  "tag--info": "lc-Tag-module__tag--info___RTH0C",
  "tag--purple": "lc-Tag-module__tag--purple___a0xaE",
  "tag--black": "lc-Tag-module__tag--black___qWjfD",
  "tag--outline": "lc-Tag-module__tag--outline___aZXtr",
  "tag--small": "lc-Tag-module__tag--small___xfcC5",
  "tag--medium": "lc-Tag-module__tag--medium___l5-KI",
  "tag--large": "lc-Tag-module__tag--large___ytC6Y",
  "tag--xlarge": "lc-Tag-module__tag--xlarge___2jj1d",
  icon: ao,
  "tag--text-white": "lc-Tag-module__tag--text-white___x0fnD",
  "tag--text-black": "lc-Tag-module__tag--text-black___dUvmD",
  "tag--dismissible": "lc-Tag-module__tag--dismissible___Qobyr",
  tag__node: ro,
  tag__content: _o
}, $e = "tag", oo = (t) => t ? ta(t, "#FFFFFF") > 4.5 ? "text-white" : "text-black" : "", Va = (m) => {
  var d = m, {
    className: t = "",
    children: a,
    dismissible: r = !1,
    size: _ = "medium",
    kind: o = "default",
    onRemove: l,
    outline: c = !1,
    leftNode: n,
    rightNode: s,
    customColor: i
  } = d, u = B(d, [
    "className",
    "children",
    "dismissible",
    "size",
    "kind",
    "onRemove",
    "outline",
    "leftNode",
    "rightNode",
    "customColor"
  ]);
  const v = g(
    Ee[$e],
    t,
    Ee[`${$e}--${_}`],
    Ee[`${$e}--${o}`],
    {
      [Ee[`${$e}--dismissible`]]: r,
      [Ee[`${$e}--outline`]]: c,
      [Ee[`${$e}--${oo(i)}`]]: !!i
    }
  ), f = _ === "small" ? "small" : "medium", p = () => i ? c ? {
    style: {
      backgroundColor: "transparent",
      color: i,
      borderColor: i
    }
  } : { style: { backgroundColor: i } } : {}, b = () => {
    if (i)
      return c ? i : ta(i, "#FFFFFF") > 4.5 ? "#FFFFFF" : "#000000";
  };
  return /* @__PURE__ */ e.createElement(
    P,
    A(w(w({
      className: v
    }, u), p()), {
      as: "div",
      size: "md"
    }),
    n && /* @__PURE__ */ e.createElement(
      "div",
      {
        "data-testid": "lc-tag-left-node",
        className: Ee[`${$e}__node`],
        style: { color: b() }
      },
      n
    ),
    /* @__PURE__ */ e.createElement("div", { className: Ee[`${$e}__content`] }, a),
    s && /* @__PURE__ */ e.createElement(
      "div",
      {
        "data-testid": "lc-tag-right-node",
        style: { color: b() }
      },
      s
    ),
    r && /* @__PURE__ */ e.createElement(
      "button",
      {
        title: "Remove",
        onClick: l,
        type: "button",
        className: Ee[`${$e}__remove`]
      },
      /* @__PURE__ */ e.createElement(
        D,
        {
          "data-dismiss-icon": !0,
          source: Te,
          size: f,
          customColor: b()
        }
      )
    )
  );
}, le = {
  "picker-trigger-body": "lc-TriggerBody-module__picker-trigger-body___ZWjX9",
  "picker-trigger-body--single": "lc-TriggerBody-module__picker-trigger-body--single___KNWtO",
  "picker-trigger-body__item-container": "lc-TriggerBody-module__picker-trigger-body__item-container___l2NTQ",
  "picker-trigger-body__item": "lc-TriggerBody-module__picker-trigger-body__item___0DBg1",
  "picker-trigger-body__item__content": "lc-TriggerBody-module__picker-trigger-body__item__content___VhhV-",
  "picker-trigger-body__item__avatar": "lc-TriggerBody-module__picker-trigger-body__item__avatar___TuCJ-",
  "picker-trigger-body__item__icon": "lc-TriggerBody-module__picker-trigger-body__item__icon___ZxGd8",
  "picker-trigger-body__input": "lc-TriggerBody-module__picker-trigger-body__input___ezPyz",
  "picker-trigger-body__input--compact": "lc-TriggerBody-module__picker-trigger-body__input--compact___YKrau",
  "picker-trigger-body__input--medium": "lc-TriggerBody-module__picker-trigger-body__input--medium___euht0",
  "picker-trigger-body__input--large": "lc-TriggerBody-module__picker-trigger-body__input--large___3nzb8",
  "picker-trigger-body__tag": "lc-TriggerBody-module__picker-trigger-body__tag___vbOJZ",
  "picker-trigger-body__tag--compact": "lc-TriggerBody-module__picker-trigger-body__tag--compact___X-oAF",
  "picker-trigger-body__tag--medium": "lc-TriggerBody-module__picker-trigger-body__tag--medium___Ptkd5",
  "picker-trigger-body__tag--large": "lc-TriggerBody-module__picker-trigger-body__tag--large___Saoy1"
}, ce = "picker-trigger-body", lo = ({
  isOpen: t,
  isSearchDisabled: a,
  isDisabled: r,
  placeholder: _,
  items: o,
  type: l,
  clearSearchAfterSelection: c,
  size: n,
  onItemRemove: s,
  onFilter: i
}) => {
  const u = t && !a, m = e.useRef(null);
  e.useEffect(() => {
    c && (i(""), m.current && (m.current.value = "", m.current.focus()));
  }, [o, c]);
  const d = (p) => l === "single" && t && !a ? null : p != null && p.customElement ? /* @__PURE__ */ e.createElement("div", { className: le[`${ce}__item`] }, p.customElement.selectedItemBody) : /* @__PURE__ */ e.createElement("div", { className: le[`${ce}__item`] }, p.icon && /* @__PURE__ */ e.createElement(
    D,
    {
      source: p.icon,
      className: le[`${ce}__item__icon`]
    }
  ), p.avatarSrc && /* @__PURE__ */ e.createElement(
    "img",
    {
      src: p.avatarSrc,
      alt: "",
      className: le[`${ce}__item__avatar`]
    }
  ), /* @__PURE__ */ e.createElement("div", { className: le[`${ce}__item__content`] }, p.name)), v = (p) => {
    i(p.target.value);
  }, f = () => /* @__PURE__ */ e.createElement(
    "input",
    {
      ref: m,
      className: g(
        le[`${ce}__input`],
        le[`${ce}__input--${n}`]
      ),
      placeholder: "Select option",
      onChange: v,
      autoFocus: !0
    }
  );
  return !o || o.length === 0 ? u ? f() : /* @__PURE__ */ e.createElement("div", null, _) : /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g(le[ce], {
        [le[`${ce}--single`]]: l === "single"
      })
    },
    /* @__PURE__ */ e.createElement("div", { className: le[`${ce}__item-container`] }, l === "single" ? d(o[0]) : o.map((p) => /* @__PURE__ */ e.createElement(
      Va,
      {
        key: p.name,
        className: g(
          le[`${ce}__tag`],
          le[`${ce}__tag--${n}`]
        ),
        dismissible: !r,
        onRemove: () => s(p)
      },
      d(p)
    ))),
    u && f()
  );
}, co = "lc-Picker-module__picker___GRj9W", no = "lc-Picker-module__picker__container___s0PUc", xa = {
  picker: co,
  picker__container: no
}, Sa = "picker", Fl = (E) => {
  var h = E, {
    className: t,
    disabled: a,
    error: r,
    options: _,
    selected: o,
    size: l = "medium",
    placeholder: c = "Select option",
    isRequired: n,
    noSearchResultText: s = "No results found",
    selectAllOptionText: i,
    type: u = "single",
    searchDisabled: m = !1,
    hideClearButton: d,
    openedOnInit: v = !1,
    clearSearchAfterSelection: f,
    onSelect: p
  } = h, b = B(h, [
    "className",
    "disabled",
    "error",
    "options",
    "selected",
    "size",
    "placeholder",
    "isRequired",
    "noSearchResultText",
    "selectAllOptionText",
    "type",
    "searchDisabled",
    "hideClearButton",
    "openedOnInit",
    "clearSearchAfterSelection",
    "onSelect"
  ]);
  const [N, $] = e.useState(v), [y, k] = e.useState(null), C = e.useRef(null), x = g(xa[Sa], t);
  e.useEffect(() => {
    if (N) {
      const F = (q) => {
        var Be;
        (Be = C.current) != null && Be.contains(q.target) || $(!1);
      }, U = (q) => {
        q.key === L.tab && $(!1);
      };
      return document.addEventListener("mousedown", F), document.addEventListener("keydown", U), () => {
        document.removeEventListener("mousedown", F), document.addEventListener("keydown", U);
      };
    } else
      k(null);
  }, [N]);
  const H = (F) => {
    const U = F.target;
    a || U.getAttribute("data-dismiss-icon") || $((q) => !q);
  }, re = () => {
    $(!1);
  }, ne = (F) => {
    if (u === "single")
      return $(!1), p([F]);
    const U = F.key, q = se;
    if (!q)
      return p([F]);
    const Be = q.includes(
      U
    ) ? q.filter((Mt) => Mt !== U) : q.concat(U);
    if ((Be == null ? void 0 : Be.length) === 0)
      return p(null);
    const ja = _.filter(
      (Mt) => Be.includes(Mt.key)
    );
    p(ja);
  }, Oe = (F) => !F.disabled && !F.groupHeader && F.key !== Qe, He = () => {
    $(!1);
    const F = Ge.filter(Oe);
    p(F);
  }, De = () => {
    $(!1), p(null);
  }, ze = (F) => k(F), ye = (F) => {
    const U = o ? o.filter((q) => q !== F) : null;
    if ((U == null ? void 0 : U.length) === 0)
      return p(null);
    p(U);
  }, Ge = e.useMemo(() => y ? _.filter((F) => {
    if (F.groupHeader)
      return !1;
    const U = y.toLowerCase();
    return F.name.toLowerCase().includes(U);
  }) : _, [y, _]), se = e.useMemo(() => o ? o.map((F) => F.key) : null, [o]);
  return /* @__PURE__ */ e.createElement("div", { ref: C, className: x, id: b.id }, /* @__PURE__ */ e.createElement("div", { className: xa[`${Sa}__container`] }, /* @__PURE__ */ e.createElement(
    X_,
    {
      testId: b["data-testid"],
      isSearchDisabled: m,
      isError: r,
      isOpen: N,
      isDisabled: a,
      isItemSelected: !!o,
      isRequired: n,
      isMultiSelect: u === "multi",
      size: l,
      hideClearButton: d,
      onTrigger: H,
      onClear: De
    },
    /* @__PURE__ */ e.createElement(
      lo,
      {
        isOpen: N,
        isSearchDisabled: m,
        isDisabled: a,
        placeholder: c,
        items: o,
        type: u,
        size: l,
        clearSearchAfterSelection: f,
        onItemRemove: ye,
        onFilter: ze
      }
    )
  ), /* @__PURE__ */ e.createElement(
    Z_,
    {
      selectedItemsKeys: se,
      items: Ge,
      isOpen: N,
      isMultiSelect: u === "multi",
      emptyStateText: s,
      selectAllOptionText: i,
      onClose: re,
      onSelect: ne,
      onSelectAll: He
    }
  )));
}, so = [
  "normal",
  "error",
  "success"
], io = (t) => Math.min(Math.max(t, 0), 100);
function Ya(t, a = 0) {
  return t === "error" ? 0 : io(parseInt(a.toString(), 10));
}
function qa(t, a) {
  return !so.includes(t) && a >= 100 ? "normal" : t || "normal";
}
const dt = {
  "progress-circle": "lc-ProgressCircle-module__progress-circle___NRHCi",
  "progress-circle--small": "lc-ProgressCircle-module__progress-circle--small___8FVwG",
  "progress-circle--medium": "lc-ProgressCircle-module__progress-circle--medium___XlzEX",
  "progress-circle--large": "lc-ProgressCircle-module__progress-circle--large___qxa2y",
  "progress-circle__bg-line--normal": "lc-ProgressCircle-module__progress-circle__bg-line--normal___nDNwj",
  "progress-circle__bg-line--success": "lc-ProgressCircle-module__progress-circle__bg-line--success___1aP-O",
  "progress-circle__bg-line--error": "lc-ProgressCircle-module__progress-circle__bg-line--error___oqMZL",
  "progress-circle__indicator--normal": "lc-ProgressCircle-module__progress-circle__indicator--normal___0SdkX",
  "progress-circle__indicator--success": "lc-ProgressCircle-module__progress-circle__indicator--success___fLwRR",
  "progress-circle__indicator--error": "lc-ProgressCircle-module__progress-circle__indicator--error___nwJS9"
}, uo = {
  small: 2,
  medium: 3,
  large: 4
}, mo = {
  small: 15,
  medium: 36,
  large: 56
}, ut = "progress-circle", po = e.forwardRef(
  (c, l) => {
    var n = c, {
      status: t = "normal",
      progressValue: a,
      className: r,
      size: _ = "medium"
    } = n, o = B(n, [
      "status",
      "progressValue",
      "className",
      "size"
    ]);
    const s = qa(t, a), i = Ya(s, a), u = uo[_], m = mo[_], d = g(
      dt[ut],
      {
        [dt[`${ut}--${_}`]]: _,
        [dt[`${ut}--${t}`]]: t
      },
      r
    ), v = 2 * Math.PI * ((m - u) / 2), f = {
      strokeDasharray: v.toFixed(3),
      strokeDashoffset: `${((100 - i) / 100 * v).toFixed(3)}px`
    }, p = `${m / 2} ${m / 2} ${m} ${m}`;
    return /* @__PURE__ */ e.createElement(
      "div",
      w({
        className: d,
        ref: l,
        role: "progressbar"
      }, o),
      /* @__PURE__ */ e.createElement("svg", { viewBox: p }, /* @__PURE__ */ e.createElement(
        "circle",
        {
          className: dt[`${ut}__bg-line--${t}`],
          cx: m,
          cy: m,
          r: (m - u) / 2,
          fill: "none",
          strokeWidth: u
        }
      ), /* @__PURE__ */ e.createElement(
        "circle",
        {
          className: dt[`${ut}__indicator--${t}`],
          style: f,
          cx: m,
          cy: m,
          r: (m - u) / 2,
          fill: "none",
          strokeWidth: u
        }
      ))
    );
  }
), Tt = {
  "progress-bar": "lc-ProgressBar-module__progress-bar___3pa3d",
  "progress-bar--small": "lc-ProgressBar-module__progress-bar--small___7nQKx",
  "progress-bar--medium": "lc-ProgressBar-module__progress-bar--medium___cM44p",
  "progress-bar--large": "lc-ProgressBar-module__progress-bar--large___UlzNR",
  "progress-bar--error": "lc-ProgressBar-module__progress-bar--error___MulqI",
  "progress-bar--success": "lc-ProgressBar-module__progress-bar--success___p7nB-",
  "progress-bar--normal": "lc-ProgressBar-module__progress-bar--normal___ZwCb-",
  "progress-bar__indicator--success": "lc-ProgressBar-module__progress-bar__indicator--success___wRjR3",
  "progress-bar__indicator--normal": "lc-ProgressBar-module__progress-bar__indicator--normal___0Uhle"
}, Dt = "progress-bar", go = e.forwardRef(
  (c, l) => {
    var n = c, {
      status: t = "normal",
      percent: a,
      size: r = "medium",
      className: _ = ""
    } = n, o = B(n, [
      "status",
      "percent",
      "size",
      "className"
    ]);
    const s = qa(t, a), i = Ya(s, a), u = g(
      Tt[Dt],
      {
        [Tt[`${Dt}--${r}`]]: r,
        [Tt[`${Dt}--${t}`]]: t
      },
      _
    );
    return /* @__PURE__ */ e.createElement(
      "div",
      A(w({}, o), {
        className: u,
        ref: l,
        role: "progressbar"
      }),
      /* @__PURE__ */ e.createElement(
        "div",
        {
          className: Tt[`${Dt}__indicator--${t}`],
          style: { width: `${i}%` }
        }
      )
    );
  }
), de = {
  "promo-banner": "lc-PromoBanner-module__promo-banner___-mGpq",
  "promo-banner__content": "lc-PromoBanner-module__promo-banner__content___kRWar",
  "promo-banner__wrapper": "lc-PromoBanner-module__promo-banner__wrapper___I-8cl",
  "promo-banner__header": "lc-PromoBanner-module__promo-banner__header___ebYHW",
  "promo-banner__img": "lc-PromoBanner-module__promo-banner__img___eaShb",
  "promo-banner__button-text": "lc-PromoBanner-module__promo-banner__button-text___taYKa",
  "promo-banner--light": "lc-PromoBanner-module__promo-banner--light___eJxOR",
  "promo-banner__footer": "lc-PromoBanner-module__promo-banner__footer___8OjvH",
  "promo-banner--small": "lc-PromoBanner-module__promo-banner--small___7sWLV",
  "promo-banner--large": "lc-PromoBanner-module__promo-banner--large___pMiCU",
  "promo-banner__close-icon": "lc-PromoBanner-module__promo-banner__close-icon___GowBL"
}, fo = 400, bo = 800, vo = 500, ue = "promo-banner", Rl = ({
  className: t,
  buttonText: a,
  children: r,
  header: _,
  img: o,
  light: l = !1,
  linkText: c,
  onButtonClick: n,
  onClose: s,
  onLinkClick: i
}) => {
  const u = e.useRef(null), [m, d] = e.useState("medium"), v = g(
    de[ue],
    {
      [de[`${ue}--light`]]: l,
      [de[`${ue}--small`]]: m === "small",
      [de[`${ue}--large`]]: m === "large"
    },
    t
  );
  e.useEffect(() => {
    const p = () => u.current && u.current.offsetWidth <= fo ? d("small") : u.current && u.current.offsetWidth >= bo ? d("large") : d("medium"), b = La(
      p,
      vo
    );
    return window.addEventListener("resize", b), p(), () => {
      b.cancel(), window.removeEventListener("resize", p);
    };
  }, []);
  const f = /* @__PURE__ */ e.createElement("div", { className: de[`${ue}__footer`] }, a && /* @__PURE__ */ e.createElement(
    M,
    {
      kind: "primary",
      size: "compact",
      onClick: n,
      className: de[`${ue}__button-text`]
    },
    a
  ), c && /* @__PURE__ */ e.createElement(M, { size: "compact", kind: "text", onClick: i }, c));
  return /* @__PURE__ */ e.createElement("div", { ref: u, className: v }, /* @__PURE__ */ e.createElement("div", { className: de[`${ue}__content`] }, o && /* @__PURE__ */ e.createElement("img", { src: o, className: de[`${ue}__img`] }), /* @__PURE__ */ e.createElement("div", { className: de[`${ue}__wrapper`] }, /* @__PURE__ */ e.createElement("div", { className: de[`${ue}__header`] }, _), /* @__PURE__ */ e.createElement(P, { as: "div" }, r), m !== "large" && f), m === "large" && f), s && /* @__PURE__ */ e.createElement(
    "button",
    {
      type: "button",
      className: de[`${ue}__close-icon`],
      onClick: s
    },
    /* @__PURE__ */ e.createElement(D, { source: Te, size: "large", kind: "primary" })
  ));
}, Ie = {
  "main-wrapper": "lc-PromoBannerV2-module__main-wrapper___U-dPS",
  "promo-banner-v2": "lc-PromoBannerV2-module__promo-banner-v2___HkNxR",
  "promo-banner-v2__content": "lc-PromoBannerV2-module__promo-banner-v2__content___65vEP",
  "promo-banner-v2__content__cta": "lc-PromoBannerV2-module__promo-banner-v2__content__cta___II2hK",
  "promo-banner-v2__content__cta__secondary": "lc-PromoBannerV2-module__promo-banner-v2__content__cta__secondary___17BKS",
  "promo-banner-v2__additional-content": "lc-PromoBannerV2-module__promo-banner-v2__additional-content___LpU9B",
  "promo-banner-v2__close": "lc-PromoBannerV2-module__promo-banner-v2__close___rOh-U",
  "promo-banner-v2__close__btn": "lc-PromoBannerV2-module__promo-banner-v2__close__btn___5AZQC"
}, Me = "promo-banner-v2", Ml = ({
  children: t,
  className: a,
  additionalContent: r,
  primaryButton: _,
  secondaryButton: o,
  onClose: l
}) => {
  const c = g(Ie[Me], a);
  return /* @__PURE__ */ e.createElement("div", { className: Ie["main-wrapper"] }, /* @__PURE__ */ e.createElement("div", { role: "banner", className: c }, /* @__PURE__ */ e.createElement("div", { className: Ie[`${Me}__content`] }, t, (_ || o) && /* @__PURE__ */ e.createElement("div", { className: Ie[`${Me}__content__cta`] }, _ && /* @__PURE__ */ e.createElement(
    M,
    {
      kind: (_ == null ? void 0 : _.kind) || "high-contrast",
      onClick: _.handleClick
    },
    _.label
  ), o && /* @__PURE__ */ e.createElement(
    M,
    {
      kind: (o == null ? void 0 : o.kind) || "text",
      onClick: o.handleClick,
      className: Ie[`${Me}__content__cta__secondary`]
    },
    o.label
  ))), r && /* @__PURE__ */ e.createElement("div", { className: Ie[`${Me}__additional-content`] }, r), l && /* @__PURE__ */ e.createElement("div", { className: Ie[`${Me}__close`] }, /* @__PURE__ */ e.createElement(
    M,
    {
      className: Ie[`${Me}__close__btn`],
      onClick: l,
      icon: /* @__PURE__ */ e.createElement(D, { source: Te, kind: "primary" }),
      kind: "plain",
      size: "compact"
    }
  ))));
}, we = {
  "radio-button": "lc-RadioButton-module__radio-button___WaToM",
  "radio-button__circle": "lc-RadioButton-module__radio-button__circle___wimWA",
  "radio-button__label": "lc-RadioButton-module__radio-button__label___i7ygg",
  "radio-button__inner-circle": "lc-RadioButton-module__radio-button__inner-circle___qkRVf",
  "radio-button__text": "lc-RadioButton-module__radio-button__text___mT8c0",
  "radio-button__input": "lc-RadioButton-module__radio-button__input___7OTAK",
  "radio-button__helper": "lc-RadioButton-module__radio-button__helper___r8gJJ",
  "radio-button--selected": "lc-RadioButton-module__radio-button--selected___s9lqj",
  "radio-button--disabled": "lc-RadioButton-module__radio-button--disabled___wHSA7"
}, Ce = "radio-button", Ll = e.forwardRef(
  (n, c) => {
    var s = n, { children: t, className: a = "", description: r, checked: _, disabled: o } = s, l = B(s, ["children", "className", "description", "checked", "disabled"]);
    const i = g(we[Ce], a, {
      [we[`${Ce}--selected`]]: _,
      [we[`${Ce}--disabled`]]: o
    });
    return /* @__PURE__ */ e.createElement("div", { className: i }, /* @__PURE__ */ e.createElement("label", { className: we[`${Ce}__label`] }, /* @__PURE__ */ e.createElement("div", { className: we[`${Ce}__circle`] }, /* @__PURE__ */ e.createElement("span", { className: we[`${Ce}__inner-circle`] }), /* @__PURE__ */ e.createElement(
      "input",
      A(w({}, l), {
        className: we[`${Ce}__input`],
        ref: c,
        type: "radio",
        checked: _,
        disabled: o
      })
    )), t && /* @__PURE__ */ e.createElement(P, { as: "div", size: "md", className: we[`${Ce}__text`] }, t)), r && /* @__PURE__ */ e.createElement(Rt, { className: we[`${Ce}__helper`] }, r));
  }
), ve = {
  "search-input": "lc-Search-module__search-input___FsbCk",
  "search-input--compact": "lc-Search-module__search-input--compact___498To",
  "search-input--medium": "lc-Search-module__search-input--medium___Hwteg",
  "search-input--large": "lc-Search-module__search-input--large___-IBuC",
  "search-input--focused": "lc-Search-module__search-input--focused___i2eul",
  "search-input--disabled": "lc-Search-module__search-input--disabled___LSJ2e",
  "search-input--collapsable": "lc-Search-module__search-input--collapsable___5GaIH",
  "search-input__search-icon": "lc-Search-module__search-input__search-icon___BBQoD",
  "search-input--collapsable--open": "lc-Search-module__search-input--collapsable--open___vqTpD",
  "search-input__clear-icon": "lc-Search-module__search-input__clear-icon___LoNkc",
  "search-input__loader": "lc-Search-module__search-input__loader___pkpIG",
  "search-input__input": "lc-Search-module__search-input__input___OhyTr"
}, pe = "search-input", ho = `${pe}__input`, Ol = ({
  isCollapsable: t,
  isDisabled: a,
  isLoading: r,
  placeholder: _ = "Search ...",
  size: o = "medium",
  value: l,
  className: c,
  onChange: n
}) => {
  const [s, i] = e.useState(!0), [u, m] = e.useState(!1), d = e.useRef(null), v = !!l && !a && !r, f = t && !s && "true", p = g(
    c,
    ve[pe],
    ve[`${pe}--${o}`],
    u && ve[`${pe}--focused`],
    a && ve[`${pe}--disabled`],
    t && ve[`${pe}--collapsable`],
    !s && ve[`${pe}--collapsable--open`]
  );
  e.useEffect(() => {
    t && l && i(!1);
  }, [t]);
  const b = (k) => {
    const C = k.currentTarget.value;
    n(C);
  }, E = () => {
    n("");
  }, h = () => {
    var k;
    (k = d.current) == null || k.focus();
  }, N = () => {
    t && i(!1), m(!0);
  }, $ = () => {
    t && !l && i(!0), m(!1);
  }, y = (k) => {
    k.key === L.enter && (k.preventDefault(), n(l));
  };
  return /* @__PURE__ */ e.createElement(
    "div",
    {
      "aria-expanded": f,
      role: "search",
      className: p,
      onClick: h
    },
    /* @__PURE__ */ e.createElement(
      D,
      {
        className: ve[`${pe}__search-icon`],
        source: or,
        disabled: a,
        kind: "primary"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "input",
      {
        role: "searchbox",
        ref: d,
        className: ve[ho],
        type: "text",
        value: l,
        placeholder: _,
        onChange: b,
        onBlur: $,
        onFocus: N,
        onKeyDown: y,
        disabled: a
      }
    ),
    v && /* @__PURE__ */ e.createElement(
      M,
      {
        "aria-label": "Clear search",
        title: "Clear search",
        className: ve[`${pe}__clear-icon`],
        onClick: E,
        icon: /* @__PURE__ */ e.createElement(D, { source: Te, kind: "primary" }),
        kind: "text",
        size: "compact"
      }
    ),
    r && /* @__PURE__ */ e.createElement(
      "div",
      {
        "data-testid": `${pe}-loader`,
        className: ve[`${pe}__loader`]
      },
      /* @__PURE__ */ e.createElement(aa, { size: "small" })
    )
  );
}, ko = "lc-Switch-module__switch__input___NiiOR", yo = "lc-Switch-module__switch__container___79F7W", Eo = "lc-Switch-module__switch__track___2wr2M", $o = "lc-Switch-module__switch__slider___WHd--", wo = "lc-Switch-module__switch__loader___FE-fA", Co = "lc-Switch-module__switch__icon___-O1qY", Z = {
  switch: "lc-Switch-module__switch___AaA2k",
  "switch__track--enabled": "lc-Switch-module__switch__track--enabled___sy4Xl",
  "switch__track--on": "lc-Switch-module__switch__track--on___aOMWe",
  "switch__track--off": "lc-Switch-module__switch__track--off___NAisj",
  "switch--large": "lc-Switch-module__switch--large___pSoJ7",
  "switch--compact": "lc-Switch-module__switch--compact___OGFIX",
  "switch--medium": "lc-Switch-module__switch--medium___EcZwT",
  switch__input: ko,
  "switch__input--disabled": "lc-Switch-module__switch__input--disabled___Po8gh",
  switch__container: yo,
  switch__track: Eo,
  "switch__track--disabled": "lc-Switch-module__switch__track--disabled___HkDhl",
  switch__slider: $o,
  "switch__slider--large": "lc-Switch-module__switch__slider--large___WoEgi",
  "switch__slider--large--on": "lc-Switch-module__switch__slider--large--on___7Qp3G",
  "switch__slider--large--off": "lc-Switch-module__switch__slider--large--off___iRMmD",
  "switch__slider--compact": "lc-Switch-module__switch__slider--compact___uZ-lS",
  "switch__slider--compact--on": "lc-Switch-module__switch__slider--compact--on___FykE3",
  "switch__slider--compact--off": "lc-Switch-module__switch__slider--compact--off___s5yR8",
  "switch__slider--medium": "lc-Switch-module__switch__slider--medium___uhptz",
  "switch__slider--medium--on": "lc-Switch-module__switch__slider--medium--on___SfeAV",
  "switch__slider--medium--off": "lc-Switch-module__switch__slider--medium--off___eXJJz",
  switch__loader: wo,
  "switch__loader--compact": "lc-Switch-module__switch__loader--compact___3imUh",
  "switch__loader--medium": "lc-Switch-module__switch__loader--medium___2zi05",
  "switch__loader--large": "lc-Switch-module__switch__loader--large___vLRFa",
  switch__icon: Co
}, j = "switch", Hl = (m) => {
  var d = m, {
    className: t = "",
    defaultOn: a = !1,
    disabled: r = !1,
    name: _ = j,
    on: o,
    onChange: l,
    size: c = "large",
    state: n = "regular",
    innerRef: s,
    ariaLabel: i
  } = d, u = B(d, [
    "className",
    "defaultOn",
    "disabled",
    "name",
    "on",
    "onChange",
    "size",
    "state",
    "innerRef",
    "ariaLabel"
  ]);
  const v = o !== void 0, [f, p] = e.useState(
    () => v ? o : a
  ), b = v ? o : f, E = n === "loading", h = n === "locked", N = c === "large" ? "small" : "xsmall", $ = b ? "on" : "off", y = r || E || h, k = y ? "disabled" : "enabled", C = g(
    Z[j],
    Z[`${j}--${c}`],
    t
  ), x = (H) => {
    l == null || l(H, !b), v || (H.stopPropagation(), p((re) => !re));
  };
  return /* @__PURE__ */ e.createElement("span", { className: C }, /* @__PURE__ */ e.createElement(
    "input",
    w({
      type: "checkbox",
      className: g(
        Z[`${j}__input`],
        Z[`${j}__input--${k}`]
      ),
      onChange: x,
      checked: b,
      name: _,
      ref: s,
      disabled: y,
      "aria-label": i
    }, u)
  ), /* @__PURE__ */ e.createElement("span", { className: Z[`${j}__container`] }, /* @__PURE__ */ e.createElement(
    "span",
    {
      className: g(
        Z[`${j}__track`],
        Z[`${j}__track--${$}`],
        Z[`${j}__track--${k}`]
      )
    }
  ), /* @__PURE__ */ e.createElement(
    "span",
    {
      className: g(
        Z[`${j}__slider`],
        Z[`${j}__slider--${c}`],
        Z[`${j}__slider--${c}--${$}`]
      )
    },
    E && /* @__PURE__ */ e.createElement(
      aa,
      {
        className: g(
          Z[`${j}__loader`],
          Z[`${j}__loader--${c}`]
        )
      }
    ),
    h && /* @__PURE__ */ e.createElement(
      D,
      {
        className: Z[`${j}__icon`],
        "data-testid": "lock-icon",
        size: N,
        source: lr,
        kind: "primary"
      }
    )
  )));
}, No = "lc-Tab-module__tab___fME7u", xo = "lc-Tab-module__tab__count___gc8tA", So = "lc-Tab-module__tab__badge___W9c-T", Ke = {
  tab: No,
  tab__count: xo,
  "tab--compact": "lc-Tab-module__tab--compact___4qead",
  "tab--medium": "lc-Tab-module__tab--medium___bHnGV",
  "tab--large": "lc-Tab-module__tab--large___JL30g",
  tab__badge: So,
  "tab--selected": "lc-Tab-module__tab--selected___c0EkL",
  "tab--disabled": "lc-Tab-module__tab--disabled___URdTh"
}, je = "tab", zl = (n) => {
  var s = n, {
    children: t,
    className: a,
    count: r,
    isSelected: _,
    asBadge: o,
    size: l = "medium"
  } = s, c = B(s, [
    "children",
    "className",
    "count",
    "isSelected",
    "asBadge",
    "size"
  ]);
  const { disabled: i } = c, u = r !== void 0 && !o, m = r !== void 0 && o;
  return /* @__PURE__ */ e.createElement(
    P,
    A(w({}, c), {
      as: c.href ? "a" : "button",
      size: "md",
      bold: _,
      className: g(
        a,
        Ke[je],
        Ke[`${je}--${l}`],
        _ && Ke[`${je}--selected`],
        i && Ke[`${je}--disabled`]
      )
    }),
    t,
    u && /* @__PURE__ */ e.createElement(P, { as: "span", size: "md", className: Ke[`${je}__count`] }, "(", r, ")"),
    m && /* @__PURE__ */ e.createElement(
      o_,
      {
        "data-testid": "tab-badge",
        count: r,
        size: "compact",
        className: Ke[`${je}__badge`]
      }
    )
  );
}, To = "lc-TabsWrapper-module__tabs___Y2xyD", Do = "lc-TabsWrapper-module__tabs__list___i6tVO", Ja = {
  tabs: To,
  tabs__list: Do
}, Ka = "tabs", Gl = ({
  className: t,
  children: a
}) => /* @__PURE__ */ e.createElement("div", { className: g(Ja[Ka], t) }, a), Ul = ({
  className: t,
  children: a
}) => /* @__PURE__ */ e.createElement("div", { className: g(Ja[`${Ka}__list`], t) }, a), Io = ({
  className: t = "",
  innerEditableRef: a,
  inputRef: r,
  change: _,
  remove: o,
  value: l
}) => {
  const [c, n] = e.useState(!1), s = () => a.current, i = () => {
    const f = s();
    return f ? f.innerText : "";
  }, u = () => {
    r && r.current && r.current.focus();
  }, m = (f) => {
    if (f.key === L.enter) {
      f.preventDefault(), u();
      return;
    }
    f.key === L.backspace && i() === "" && (n(!0), o(), u());
  }, d = () => {
    const f = s();
    if (!(c || !f)) {
      if (f.innerText === "") {
        o();
        return;
      }
      _(f.innerText);
    }
  }, v = (f) => {
    f.preventDefault();
    const p = f.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", !1, na(p));
  };
  return /* @__PURE__ */ e.createElement(
    "div",
    {
      ref: a,
      className: t,
      contentEditable: !0,
      onPaste: v,
      onBlur: d,
      onKeyDown: m,
      dangerouslySetInnerHTML: { __html: na(l) }
    }
  );
}, gt = {
  "tag-input": "lc-TagInput-module__tag-input___CLVY-",
  "tag-input--error": "lc-TagInput-module__tag-input--error___RP0SB",
  "tag-input__input": "lc-TagInput-module__tag-input__input___dgEYB",
  "tag-input__input--medium": "lc-TagInput-module__tag-input__input--medium___DYF7X",
  "tag-input__input--large": "lc-TagInput-module__tag-input__input--large___G6iEO",
  "tag-input__tag__content": "lc-TagInput-module__tag-input__tag__content___x95-M"
}, Ao = "tag-input__tag", Bo = ({
  children: t,
  index: a,
  remove: r,
  validator: _,
  inputRef: o,
  update: l,
  size: c
}) => {
  const n = e.useMemo(() => _ !== void 0 ? _(t) : !0, [t, _]), s = e.useRef(null), i = () => r(a);
  return /* @__PURE__ */ e.createElement(
    Va,
    {
      kind: n ? "default" : "error",
      dismissible: !0,
      size: c,
      onRemove: i
    },
    /* @__PURE__ */ e.createElement(
      Io,
      {
        value: t,
        inputRef: o,
        innerEditableRef: s,
        className: gt[`${Ao}__content`],
        change: (u) => l(a, u),
        remove: i,
        validator: _
      }
    )
  );
}, It = "tag-input", Po = [
  L.enter,
  L.spacebar,
  L.tab,
  L.semicolon,
  L.comma
], Fo = [L.backspace, L.delete], Ro = (s) => {
  var i = s, {
    id: t,
    tags: a,
    onChange: r,
    validator: _,
    error: o,
    placeholder: l,
    size: c = "medium"
  } = i, n = B(i, [
    "id",
    "tags",
    "onChange",
    "validator",
    "error",
    "placeholder",
    "size"
  ]);
  const u = g(gt[It], {
    [gt[`${It}--error`]]: o
  }), m = g(
    gt[`${It}__input`],
    gt[`${It}__input--${c}`]
  ), [d, v] = e.useState(""), f = e.useRef(null), p = (y) => {
    r([...a || [], y]), v("");
  }, b = (y) => {
    const k = [...a || []];
    k.splice(y, 1), r(k);
  }, E = (y) => v(y.target.value), h = (y) => {
    if (Po.includes(y.key)) {
      if (y.preventDefault(), d === "")
        return;
      p(d);
    } else if (Fo.includes(y.key)) {
      if (d !== "" || !(a != null && a.length))
        return;
      b(a.length - 1);
    }
  }, N = (y, k) => {
    const C = [...a || []];
    C.reduce(
      (H, re, ne) => H + (re === k && ne !== y ? 1 : 0),
      0
    ) > 0 ? C.splice(y, 1) : C[y] = k, r(C);
  }, $ = (y) => {
    y.preventDefault();
    const C = y.clipboardData.getData("text/plain").split(/[\s,;\n]+/);
    r([...a || [], ...C]);
  };
  return /* @__PURE__ */ e.createElement(e.Fragment, null, /* @__PURE__ */ e.createElement("div", { className: u }, a == null ? void 0 : a.map((y, k) => /* @__PURE__ */ e.createElement(
    Bo,
    {
      index: k,
      key: `${k}${y}`,
      update: N,
      remove: b,
      inputRef: f,
      validator: _,
      size: c
    },
    y
  )), /* @__PURE__ */ e.createElement(
    "input",
    A(w({}, n), {
      id: t,
      ref: f,
      className: m,
      placeholder: l,
      value: d,
      onChange: E,
      onKeyDown: h,
      onPaste: $
    })
  )), o && /* @__PURE__ */ e.createElement(ra, null, o));
}, Mo = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i, Lo = "name@company.com", Oo = (t) => Mo.test(t), Wl = ({
  id: t,
  tags: a,
  onChange: r,
  error: _,
  placeholder: o = Lo,
  size: l
}) => /* @__PURE__ */ e.createElement(
  Ro,
  {
    id: t,
    tags: a,
    error: _,
    onChange: r,
    placeholder: o,
    validator: Oo,
    size: l
  }
), Ho = "lc-Toast-module__toast___j5Amn", zo = "lc-Toast-module__toast__content___HjaNw", Go = "lc-Toast-module__toast__actions___JWu-1", Uo = "lc-Toast-module__toast__actions__button___Iblrl", V = {
  toast: Ho,
  toast__content: zo,
  toast__actions: Go,
  toast__actions__button: Uo,
  "toast__actions__button--success": "lc-Toast-module__toast__actions__button--success___5JmFw",
  "toast__actions__button--warning": "lc-Toast-module__toast__actions__button--warning___zGQ-Q",
  "toast__actions__button--error": "lc-Toast-module__toast__actions__button--error___PuTv3",
  "toast__actions__button--info": "lc-Toast-module__toast__actions__button--info___fNtsj",
  "toast__actions--custom": "lc-Toast-module__toast__actions--custom___lm-pM",
  "toast--success": "lc-Toast-module__toast--success___7UC6m",
  "toast--warning": "lc-Toast-module__toast--warning___pteb7",
  "toast--error": "lc-Toast-module__toast--error___trg--",
  "toast--info": "lc-Toast-module__toast--info___IOp44",
  "toast-wrapper": "lc-Toast-module__toast-wrapper___Oiwqp",
  "toast-wrapper--fixed": "lc-Toast-module__toast-wrapper--fixed___QYxRI",
  "toast-wrapper--block": "lc-Toast-module__toast-wrapper--block___j0kGt",
  "toast-wrapper--horizontal-center": "lc-Toast-module__toast-wrapper--horizontal-center___t9AFd",
  "toast-wrapper--horizontal-left": "lc-Toast-module__toast-wrapper--horizontal-left___-GY4I",
  "toast-wrapper--horizontal-right": "lc-Toast-module__toast-wrapper--horizontal-right___VVMgm",
  "toast-wrapper--vertical-top": "lc-Toast-module__toast-wrapper--vertical-top___uBfEG",
  "toast-appear--slide": "lc-Toast-module__toast-appear--slide___G7GAp",
  "toast-appear-active--slide": "lc-Toast-module__toast-appear-active--slide___brfgK",
  "toast-exit--slide": "lc-Toast-module__toast-exit--slide___aqiBq",
  "toast-exit-active--slide": "lc-Toast-module__toast-exit-active--slide___WryMi",
  "toast-wrapper--vertical-bottom": "lc-Toast-module__toast-wrapper--vertical-bottom___erPuH",
  "toast-appear--fade": "lc-Toast-module__toast-appear--fade___oUwHm",
  "toast-appear-active--fade": "lc-Toast-module__toast-appear-active--fade___D3--J",
  "toast-exit--fade": "lc-Toast-module__toast-exit--fade___iDA1p",
  "toast-exit-active--fade": "lc-Toast-module__toast-exit-active--fade___tL7sb"
}, Wo = {
  success: {
    source: Da
  },
  warning: {
    source: Ta
  },
  error: {
    source: Ia
  },
  info: {
    source: Zt
  }
}, Ae = "toast", Vo = (n) => {
  var s = n, {
    action: t,
    className: a,
    children: r,
    removable: _,
    kind: o = "info",
    onClose: l
  } = s, c = B(s, [
    "action",
    "className",
    "children",
    "removable",
    "kind",
    "onClose"
  ]);
  const i = g(
    V[Ae],
    V[`${Ae}--${o}`],
    a
  ), u = typeof r == "string", m = (d) => d && d.closesOnClick && l ? (d.onClick(), l()) : d.onClick();
  return /* @__PURE__ */ e.createElement("div", w({ className: i }, c), /* @__PURE__ */ e.createElement("div", { className: V[`${Ae}__icon`] }, /* @__PURE__ */ e.createElement(D, A(w({}, Wo[o]), { size: "medium" }))), /* @__PURE__ */ e.createElement("div", { className: V[`${Ae}__content`] }, u ? /* @__PURE__ */ e.createElement(P, { as: "div" }, r) : r), (t || _) && /* @__PURE__ */ e.createElement("div", { className: V[`${Ae}__actions`] }, t && /* @__PURE__ */ e.createElement(
    M,
    {
      className: V[`${Ae}__actions--custom`],
      kind: "text",
      size: "compact",
      onClick: () => m(t)
    },
    t.label
  ), _ && /* @__PURE__ */ e.createElement(
    M,
    {
      className: g(
        V[`${Ae}__actions__button`],
        V[`${Ae}__actions__button--${o}`]
      ),
      "aria-label": "Close toast",
      kind: "text",
      size: "compact",
      onClick: l,
      icon: /* @__PURE__ */ e.createElement(D, { source: Te, size: "medium" })
    }
  )));
}, Yo = 200, mt = "toast-wrapper", Vl = ({
  className: t,
  toasts: a = [],
  fixed: r = !0,
  block: _ = !1,
  animationType: o = "slide",
  verticalPosition: l = "top",
  horizontalPosition: c = "center"
}) => {
  const n = g(
    V[mt],
    {
      [V[`${mt}--fixed`]]: r,
      [V[`${mt}--block`]]: !r && _,
      [V[`${mt}--horizontal-${c}`]]: c,
      [V[`${mt}--vertical-${l}`]]: l
    },
    t
  );
  return /* @__PURE__ */ e.createElement("div", { className: n }, /* @__PURE__ */ e.createElement(Ra, { component: null }, a.map(({ id: s, kind: i, content: u, removable: m, action: d, onClose: v }) => /* @__PURE__ */ e.createElement(
    ea,
    {
      key: s,
      classNames: {
        enter: V[`toast-appear--${o}`],
        enterActive: V[`toast-appear-active--${o}`],
        exit: V[`toast-exit--${o}`],
        exitActive: V[`toast-exit-active--${o}`]
      },
      timeout: Yo
    },
    /* @__PURE__ */ e.createElement(
      Vo,
      {
        kind: i,
        onClose: v,
        removable: m,
        action: d
      },
      u
    )
  ))));
}, qo = "lc-Textarea-module__textarea___g6U8F", At = {
  textarea: qo,
  "textarea--focused": "lc-Textarea-module__textarea--focused___1vtEx",
  "textarea--disabled": "lc-Textarea-module__textarea--disabled___xXaGv",
  "textarea--error": "lc-Textarea-module__textarea--error___0EGuq"
}, Bt = "textarea", Yl = e.forwardRef(
  (o, _) => {
    var l = o, { className: t, error: a } = l, r = B(l, ["className", "error"]);
    const { disabled: c, onBlur: n, onFocus: s } = r, [i, u] = e.useState(!1), m = g(t, At[Bt], {
      [At[`${Bt}--disabled`]]: c,
      [At[`${Bt}--focused`]]: i,
      [At[`${Bt}--error`]]: a
    }), d = (f) => {
      u(!1), n == null || n(f);
    }, v = (f) => {
      u(!0), s == null || s(f);
    };
    return /* @__PURE__ */ e.createElement("div", { className: m }, /* @__PURE__ */ e.createElement(
      "textarea",
      A(w({}, r), {
        ref: _,
        onFocus: v,
        onBlur: d
      })
    ));
  }
), Wt = {
  "file-upload-progress-actions": "lc-FileUploadProgressActions-module__file-upload-progress-actions___qOBr2",
  "file-upload-progress-actions__close-button": "lc-FileUploadProgressActions-module__file-upload-progress-actions__close-button___Eh4um",
  "file-upload-progress-actions__retry-button": "lc-FileUploadProgressActions-module__file-upload-progress-actions__retry-button___DqFEo"
}, Vt = "file-upload-progress-actions", Qt = ({ status: t, onCloseButtonClick: a, onRetryButtonClick: r }) => /* @__PURE__ */ e.createElement("div", { className: Wt[`${Vt}`] }, r && t === "error" && /* @__PURE__ */ e.createElement(
  "button",
  {
    type: "button",
    className: Wt[`${Vt}__retry-button`],
    "aria-label": "Retry",
    onClick: r
  },
  /* @__PURE__ */ e.createElement(D, { size: "small", source: cr })
), a && t !== "success" && /* @__PURE__ */ e.createElement(
  "button",
  {
    type: "button",
    className: Wt[`${Vt}__close-button`],
    "aria-label": "Close",
    onClick: a
  },
  /* @__PURE__ */ e.createElement(D, { size: "small", source: Te })
)), me = {
  "file-upload-progress": "lc-FileUploadProgress-module__file-upload-progress___xw8h8",
  "file-upload-progress__icon": "lc-FileUploadProgress-module__file-upload-progress__icon___p8Rfz",
  "file-upload-progress__icon--success": "lc-FileUploadProgress-module__file-upload-progress__icon--success___X40uH",
  "file-upload-progress__wrapper": "lc-FileUploadProgress-module__file-upload-progress__wrapper___01Nkq",
  "file-upload-progress__wrapper--with-icon": "lc-FileUploadProgress-module__file-upload-progress__wrapper--with-icon___GSXmA",
  "file-upload-progress__wrapper__header": "lc-FileUploadProgress-module__file-upload-progress__wrapper__header___mYBGK",
  "file-upload-progress__wrapper__header__actions": "lc-FileUploadProgress-module__file-upload-progress__wrapper__header__actions___tvhB3",
  "file-upload-progress__wrapper__header__title": "lc-FileUploadProgress-module__file-upload-progress__wrapper__header__title___2IVuv",
  "file-upload-progress__wrapper__header__title--success": "lc-FileUploadProgress-module__file-upload-progress__wrapper__header__title--success___E6DQl",
  "file-upload-progress__wrapper__header__title--error": "lc-FileUploadProgress-module__file-upload-progress__wrapper__header__title--error___5BPpI"
}, Ne = "file-upload-progress", Yt = `${Ne}__wrapper__header__title`, Jo = e.forwardRef(
  ({
    actionsVisibility: t = "hidden",
    className: a,
    icon: r,
    title: _,
    progressValue: o,
    size: l,
    status: c = "normal",
    onCloseButtonClick: n,
    onRetryButtonClick: s
  }, i) => /* @__PURE__ */ e.createElement("div", { className: me[Ne], ref: i }, r && c !== "success" && /* @__PURE__ */ e.createElement("div", { className: me[`${Ne}__icon`] }, r), c === "success" && /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g(
        me[`${Ne}__icon`],
        me[`${Ne}__icon--success`]
      )
    },
    /* @__PURE__ */ e.createElement(D, { source: Xt })
  ), /* @__PURE__ */ e.createElement(
    "div",
    {
      className: g(me[`${Ne}__wrapper`], {
        [me[`${Ne}__wrapper--with-icon`]]: r
      })
    },
    /* @__PURE__ */ e.createElement("div", { className: me[`${Ne}__wrapper__header`] }, _ && /* @__PURE__ */ e.createElement(
      "div",
      {
        className: g(me[Yt], {
          [me[`${Yt}--success`]]: c === "success",
          [me[`${Yt}--error`]]: c === "error"
        })
      },
      /* @__PURE__ */ e.createElement(P, { size: "sm", as: "div" }, _),
      "=",
      " "
    ), t !== "hidden" && /* @__PURE__ */ e.createElement(
      "div",
      {
        className: g({
          [me[`${Ne}__wrapper__header__actions`]]: t === "hover"
        })
      },
      /* @__PURE__ */ e.createElement(
        Qt,
        {
          status: c,
          onCloseButtonClick: n,
          onRetryButtonClick: s
        }
      )
    )),
    c !== "success" && /* @__PURE__ */ e.createElement(
      go,
      {
        className: a,
        percent: o,
        status: c,
        size: l
      }
    )
  ))
), R = {
  "upload-bar": "lc-UploadBar-module__upload-bar___6Or8h",
  "upload-bar__loader-test": "lc-UploadBar-module__upload-bar__loader-test___aZb2J",
  "upload-bar--error": "lc-UploadBar-module__upload-bar--error___MzvhT",
  "upload-bar__wrapper": "lc-UploadBar-module__upload-bar__wrapper___MAUeH",
  "upload-bar__wrapper__header": "lc-UploadBar-module__upload-bar__wrapper__header___x9fen",
  "upload-bar__wrapper__header__icon": "lc-UploadBar-module__upload-bar__wrapper__header__icon___Aetm5",
  "upload-bar__wrapper__header__title": "lc-UploadBar-module__upload-bar__wrapper__header__title___BNuDu",
  "upload-bar__wrapper__header__collapse-button": "lc-UploadBar-module__upload-bar__wrapper__header__collapse-button___3khMy",
  "upload-bar__wrapper__header__actions-container": "lc-UploadBar-module__upload-bar__wrapper__header__actions-container___5Pj4y",
  "upload-bar__wrapper__header__success-icon": "lc-UploadBar-module__upload-bar__wrapper__header__success-icon___ZLCdw",
  "upload-bar__wrapper__header__error-icon": "lc-UploadBar-module__upload-bar__wrapper__header__error-icon___q-sZo",
  "upload-bar__wrapper__header__loader": "lc-UploadBar-module__upload-bar__wrapper__header__loader___DKHu3",
  "upload-bar__files": "lc-UploadBar-module__upload-bar__files___3W4nx",
  "upload-bar__files--enter": "lc-UploadBar-module__upload-bar__files--enter___zGq19",
  "upload-bar__files--enter-active": "lc-UploadBar-module__upload-bar__files--enter-active___-dMVb",
  "upload-bar__files--exit": "lc-UploadBar-module__upload-bar__files--exit___MXBRY",
  "upload-bar__files--exit-active": "lc-UploadBar-module__upload-bar__files--exit-active___krGW5",
  "upload-bar__files--exit-done": "lc-UploadBar-module__upload-bar__files--exit-done___2I72G",
  "upload-bar__files-wrapper": "lc-UploadBar-module__upload-bar__files-wrapper___93bs8",
  "upload-bar__files__list": "lc-UploadBar-module__upload-bar__files__list___QJFEq"
}, X = "upload-bar", xe = `${X}__wrapper__header`, Ko = 300, jo = (t, a) => t === "success" ? /* @__PURE__ */ e.createElement("div", { className: R[`${xe}__success-icon`] }, /* @__PURE__ */ e.createElement(D, { source: Xt })) : t === "error" ? /* @__PURE__ */ e.createElement("div", { className: R[`${xe}__error-icon`] }, /* @__PURE__ */ e.createElement(D, { source: nr })) : /* @__PURE__ */ e.createElement(
  po,
  {
    className: R[`${xe}__loader`],
    status: t,
    progressValue: a
  }
), ql = ({
  children: t,
  className: a,
  progressValue: r,
  title: _,
  isExpanded: o,
  errorMessage: l,
  status: c = "normal",
  icon: n,
  size: s,
  mode: i = "multiple",
  onCloseButtonClick: u,
  onRetryButtonClick: m
}) => {
  const [d, v] = e.useState(o || !1), f = c === "error", p = c === "success", b = g(R[X], a, {
    [R[`${X}--error`]]: f,
    [R[`${X}--success`]]: p
  }), E = !(f && (m || u)), h = () => v(!d);
  return i === "single" ? /* @__PURE__ */ e.createElement("div", { className: b }, /* @__PURE__ */ e.createElement("div", { className: R[`${X}__wrapper`] }, /* @__PURE__ */ e.createElement("div", { className: R[`${xe}`] }, /* @__PURE__ */ e.createElement(
    Jo,
    {
      title: f && l || _,
      progressValue: r,
      status: c,
      icon: n,
      size: s
    }
  )), c === "error" && /* @__PURE__ */ e.createElement(
    Qt,
    {
      status: c,
      onCloseButtonClick: u,
      onRetryButtonClick: m
    }
  ))) : /* @__PURE__ */ e.createElement("div", { className: b }, /* @__PURE__ */ e.createElement(
    "div",
    {
      className: R[`${X}__wrapper`],
      onClick: h
    },
    /* @__PURE__ */ e.createElement("div", { className: R[`${xe}`] }, /* @__PURE__ */ e.createElement("div", { className: R[`${xe}__icon`] }, jo(c, r)), /* @__PURE__ */ e.createElement("div", { className: R[`${xe}__title`] }, /* @__PURE__ */ e.createElement(P, { size: "sm", as: "div" }, f ? l : _)), E && /* @__PURE__ */ e.createElement(
      "button",
      {
        className: R[`${xe}__collapse-button`],
        type: "button",
        onClick: h
      },
      d ? /* @__PURE__ */ e.createElement(D, { source: Ft }) : /* @__PURE__ */ e.createElement(D, { source: bt })
    ), c === "error" && /* @__PURE__ */ e.createElement("div", { className: R[`${xe}__actions-container`] }, /* @__PURE__ */ e.createElement(
      Qt,
      {
        status: c,
        onCloseButtonClick: u,
        onRetryButtonClick: m
      }
    )))
  ), /* @__PURE__ */ e.createElement(Ra, { component: null }, d && /* @__PURE__ */ e.createElement(
    ea,
    {
      timeout: Ko,
      classNames: {
        enter: R[`${X}__files--enter`],
        enterActive: R[`${X}__files--enter-active`],
        exit: R[`${X}__files--exit`],
        exitActive: R[`${X}__files--exit-active`],
        exitDone: R[`${X}__files--exit-done`]
      }
    },
    /* @__PURE__ */ e.createElement("div", { className: R[`${X}__files`] }, /* @__PURE__ */ e.createElement("div", { className: R[`${X}__files-wrapper`] }, /* @__PURE__ */ e.createElement("div", { className: R[`${X}__files__list`] }, t)))
  )));
};
export {
  Yo as ANIMATION_TIME,
  kl as ActionBar,
  yr as ActionMenu,
  Er as ActionMenuItem,
  yl as Alert,
  t_ as Avatar,
  o_ as Badge,
  M as Button,
  $l as Card,
  wl as Checkbox,
  F_ as DatePicker,
  il as DesignToken,
  pl as Display,
  Wl as EmailTagInput,
  Rt as FieldDescription,
  ra as FieldError,
  Cl as FieldGroup,
  Jo as FileUploadProgress,
  Qt as FileUploadProgressActions,
  Nl as Form,
  xl as FormField,
  Sl as FormGroup,
  et as Heading,
  D as Icon,
  fl as Info,
  Tl as Input,
  bl as Interactive,
  Dl as Link,
  aa as Loader,
  Il as Modal,
  K_ as ModalBase,
  Ca as ModalCloseButton,
  Bl as ModalHeader,
  Al as ModalPortal,
  Pl as NumericInput,
  Fl as Picker,
  Z_ as PickerList,
  kr as Popover,
  go as ProgressBar,
  po as ProgressCircle,
  Rl as PromoBanner,
  Ml as PromoBannerV2,
  Ll as RadioButton,
  ml as RadiusToken,
  M_ as RangeDatePicker,
  hl as Reports,
  Ol as SearchInput,
  El as SegmentedControl,
  ul as ShadowToken,
  gl as Simple,
  dl as SpacingToken,
  Hl as Switch,
  zl as Tab,
  Ul as TabsList,
  Gl as TabsWrapper,
  Va as Tag,
  Ro as TagInput,
  P as Text,
  Yl as Textarea,
  Vo as Toast,
  Vl as ToastWrapper,
  Ga as Tooltip,
  ql as UploadBar,
  vl as UserGuide,
  Jr as generateAvatarColor
};
