import { FC, PropsWithChildren, useMemo } from 'react';
import jwt from 'jsonwebtoken';
import { useGetConfig } from './hooks/useGetConfig';
import { Loader } from '~src/components/Loader';
import { Error } from '~src/components/Error';
import { ConfigContext } from './ConfigContext';

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
    const configUrl = useMemo(
        () =>
            new URLSearchParams(window.location.search).get('configUrl') ||
            jwt.decode(new URLSearchParams(window.location.hash).get('state'))
                ?.configUrl ||
            null,
        [],
    );

    const { error, isLoading, config } = useGetConfig(configUrl);

    if (!configUrl) {
        return <Error title="Missing confguration URL" />;
    }

    if (error) {
        return <Error title="Configuration error" />;
    }

    if (isLoading) {
        return <Loader label="Fetching config..." fullscreen />;
    }

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};
