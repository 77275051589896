import {
    FC,
    PropsWithChildren,
    useContext,
    useCallback,
    MouseEventHandler,
} from 'react';
import { useAuth } from './hooks/useAuth';
import { ConfigContext } from '~src/components/ConfigProvider';
import { Error } from '~src/components/Error';
import { Loader } from '~src/components/Loader';
import { AuthContext } from './AuthContext';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const { auth, error } = useAuth();

    const { configUrl } = useContext(ConfigContext);
    const resetAuth: MouseEventHandler = useCallback(
        e => {
            e.preventDefault();

            const url = new URL(
                window.location.pathname.replace('?', ''),
                window.location.origin,
            );
            url.searchParams.set('configUrl', configUrl);

            window.location.replace(url);
        },
        [configUrl],
    );

    if (error) {
        console.error(error); // eslint-disable-line no-console
        return (
            <Error
                title="Authentication failed"
                buttons={[
                    {
                        kind: 'primary',
                        children: 'Try again',
                        onClick: resetAuth,
                    },
                ]}
            />
        );
    }

    if (!auth.token) {
        return <Loader label="Authenticating..." />;
    }

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
