.fullscreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: var(--spacing-0);
    left: var(--spacing-0);
}
