import { useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';
import AccountsSDK from '@livechat/accounts-sdk';
import { ConfigContext } from '~src/components/ConfigProvider';

const csrfCookieName = '__banhammer_csrf_secret';
const csrfCookieOpts: {
    path: string;
    sameSite: 'none';
    secure: boolean;
} = {
    path: '/',
    sameSite: 'none',
    secure: true,
};

let prompt = '';

if (process.env.NODE_ENV !== 'production') {
    prompt = 'consent';
}

export const useAuth = (): {
    error: unknown | null;
    auth: { token: string | null; organizationId: string | null };
} => {
    const [auth, setAuth] = useState({
        token: null,
        organizationId: null,
    });
    const [error, setError] = useState(null);
    const { clientId, configUrl } = useContext(ConfigContext);
    const [cookies, setCookie, removeCookie] = useCookies([csrfCookieName]);
    const csrfTokenSecret = useMemo(
        () => cookies.__banhammer_csrf_secret || uuidv4(),
        [cookies.__banhammer_csrf_secret],
    );

    const sdk = useMemo(
        () =>
            new AccountsSDK({
                client_id: clientId,
                scope: 'accounts--my:ro',
                state: jwt.sign({ configUrl }, csrfTokenSecret),
                verify_state: false,
                prompt,
            }),
        [clientId, configUrl, csrfTokenSecret],
    );

    useEffect(() => {
        if (auth.token) {
            return;
        }

        setCookie(csrfCookieName, csrfTokenSecret, csrfCookieOpts);

        sdk.redirect()
            .authorizeData()
            .then(authorizeData => {
                try {
                    jwt.verify(
                        new URLSearchParams(window.location.hash).get('state'),
                        csrfTokenSecret,
                    );

                    setAuth({
                        token: authorizeData.access_token,
                        organizationId: authorizeData.organization_id,
                    });
                } catch (e) {
                    removeCookie(csrfCookieName, csrfCookieOpts);
                    setError(e);
                }
            })
            .catch(() => {
                sdk.redirect().authorize();
            });
    }, [auth, csrfTokenSecret, sdk, setCookie, removeCookie]);

    return { auth, error };
};
