export const classNames = (...args: unknown[]): string => {
    const classes = [];

    for (let i = 0; i < args.length; i++) {
        const arg = args[i];

        if (!arg) {
            continue;
        }

        const argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
            classes.push(arg);
        } else if (Array.isArray(arg)) {
            if (arg.length) {
                const inner = classNames(...arg);
                if (inner) {
                    classes.push(inner);
                }
            }
        } else if (argType === 'object') {
            if (
                arg.toString !== Object.prototype.toString &&
                !arg.toString.toString().includes('[native code]')
            ) {
                classes.push(arg.toString());
                continue;
            }

            Object.keys(arg).forEach(key => {
                if (arg?.[key]) {
                    classes.push(key);
                }
            });
        }
    }

    return classes.join(' ');
};
