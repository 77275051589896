import * as React from 'react';
import Cookies from 'universal-cookie';
import { Provider } from './CookiesContext';
export default class CookiesProvider extends React.Component {
    constructor(props) {
        super(props);
        if (props.cookies) {
            this.cookies = props.cookies;
        }
        else {
            this.cookies = new Cookies(undefined, props.defaultSetOptions);
        }
    }
    render() {
        return React.createElement(Provider, { value: this.cookies }, this.props.children);
    }
}
