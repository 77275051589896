import type { FC } from 'react';
import { Loader as Component } from '@livechat/design-system-react-components';
import { classNames } from '~src/functions';
import * as classes from './Loader.module.css';

export type LoaderProps = {
    className?: string;
    fullscreen?: boolean;
    label?: string;
};

export const Loader: FC<LoaderProps> = ({ className, fullscreen, label }) => (
    <div
        className={classNames({
            [classes.fullscreen]: fullscreen,
            [className]: !!className,
        })}
    >
        <Component data-testid="Loader-loaderComponent" label={label} />
    </div>
);

Loader.defaultProps = {
    fullscreen: true,
    label: 'Loading...',
};
