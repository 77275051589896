import { useQuery } from 'react-query';
import { useMemo } from 'react';
import type { Config } from '../types';

export const useGetConfig = (
    configUrl: string,
): {
    isLoading: boolean;
    error: unknown;
    config: Config | null;
} => {
    const { isLoading, error, data } = useQuery<{
        API_URL: string;
        CLIENT_ID: string;
    }>('config', () => fetch(configUrl).then(res => res.json()), {
        enabled: !!configUrl,
    });

    const config = useMemo(
        () =>
            data
                ? {
                      configUrl,
                      apiUrl: data.API_URL,
                      clientId: data.CLIENT_ID,
                  }
                : null,
        [data, configUrl],
    );

    return { isLoading, error, config };
};
