import type { FC, ReactNode } from 'react';
import {
    ButtonProps,
    Heading,
    Text,
    Button,
    Display,
    Icon,
} from '@livechat/design-system-react-components';
import { Error as ErrorIcon } from '@livechat/design-system-icons/react/tabler';
import * as classes from './Error.module.css';

export type ErrorProps = {
    title: ReactNode;
    description?: ReactNode;
    buttons?: ButtonProps[];
};

export const Error: FC<ErrorProps> = ({ title, description, buttons }) => (
    <Display className={classes.error}>
        <Icon source={ErrorIcon} className={classes.icon} />
        <Heading size="lg">{title}</Heading>
        {description && <Text>{description}</Text>}
        {buttons?.map((button, index) => <Button key={index} {...button} />)}
    </Display>
);
