import { FC, lazy, Suspense } from 'react';
import { Display } from '@livechat/design-system-react-components';
import { QueryClientProvider } from 'react-query';
import { CookiesProvider } from 'react-cookie';
import { queryClient } from './constants/queryClient';
import { ConfigProvider } from './components/ConfigProvider';
import { AuthProvider } from './components/AuthProvider';
import { Loader } from './components/Loader';
import * as classes from './App.module.css';

const Settings = lazy(() =>
    import('./components/Settings').then(({ SettingsView }) => ({
        default: SettingsView,
    })),
);

import '@fontsource/source-sans-pro/latin.css';
import '@livechat/design-system-react-components/dist/style.css';
import './global.css';

export const App: FC = () => {
    return (
        <Display className={classes.app}>
            <QueryClientProvider client={queryClient}>
                <CookiesProvider>
                    <ConfigProvider>
                        <AuthProvider>
                            <Suspense
                                fallback={
                                    <Loader label="Loading app..." fullscreen />
                                }
                            >
                                <Settings />
                            </Suspense>
                        </AuthProvider>
                    </ConfigProvider>
                </CookiesProvider>
            </QueryClientProvider>
        </Display>
    );
};
